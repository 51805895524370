import React from 'react'

class ShareCopy extends React.Component
{
render()
{
    return (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#0E0E0E"
        fillOpacity="0.75"
        d="M2 5H.993A.993.993 0 000 5.993v9.014c0 .548.445.993.993.993h10.014a.993.993 0 00.993-.993V14H3a1 1 0 01-1-1V5z"
      ></path>
      <path
        fill="#0E0E0E"
        fillOpacity="0.75"
        fillRule="evenodd"
        d="M4.993 12A.993.993 0 014 11.007V.993C4 .445 4.445 0 4.993 0h10.014c.548 0 .993.445.993.993v10.014a.993.993 0 01-.993.993H4.993z"
        clipRule="evenodd"
      ></path>
    </svg>
)
}
}

export default ShareCopy;