import React from 'react';
import './Product.scss'
import Bookmark from 'Components/Bookmark'
import  AppLink  from 'Components/App/Link/Link'

import { store } from 'Redux/store';
import actions from 'Redux/actions';

class Product extends React.Component
{
    constructor(props){
        
        super(props);
        
        this.state = {
            clicked: false
        };

        this.productClick = this.productClick.bind(this);
    }

    randomizeImage()
    {
        // Randomizing has to be coded yet
        return this.props.data.default_image.src.replace(".jpg","_medium.jpg").replace(".png","_medium.png")
    }


    productClick() {
        const currentState = this.state.clicked;
        this.setState({ clicked: !currentState });
        //console.log("offset" +window.pageYOffset)
        store.dispatch(actions.saveScroll( {url: window.location.href, position : window.pageYOffset}))
    }

    render()
    {
        
        const {data, className, template} = this.props;
        
        if(template !== "simple") {
        return (
            <div className={"product " + className}>
            { data &&
            <div>
                <AppLink onClick={this.productClick} className={this.state.clicked ? 'product-link clicked':' product-link'} to={ "/product/" + data.id}>
               {
                data.default_variant &&
                data.default_variant.compare_at_price ? <div className="discount">-{Math.round(data.default_variant.discount)}%</div> : null }
            
               <div className="img">
               { data.default_image  && <img alt="product" src={this.randomizeImage()} width="100%" /> }
               </div>

               <div className="prices">
                <span className="price"><span className="currency">$</span>{data.default_variant && data.default_variant.price.replace(".00","")}</span>
                { data.default_variant && data.default_variant.compare_at_price && <span className="stroke">${Math.round(data.default_variant.compare_at_price)}
                </span> }<br/>
               { data.order_count > 0 
                    && <span className="bought">{data.order_count}&nbsp;bought&nbsp;this</span> }
               </div>
               </AppLink>
           

              <Bookmark id_product={data.id} />
              </div>
            }
               <div className="clearfix"></div>
            </div>

        ) }
       else
       {
        return (
            <div className={"product " + className}>
            { data &&
                <AppLink  to={ "/product/" + data.id}>

            <div className="img">
               { data.default_image && <img alt="product" src={data.default_image.src} width="100%" /> }
            </div>

               </AppLink>
            }
            <div className="clearfix"></div>
            </div>

        )
       } 
    }
}

export default Product;