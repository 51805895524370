// reducers.js
import types from 'Redux/types';

const INITIAL_STATE = {
  methods: { card: true, apppay: false },
}

const paymentReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case types.PAYMENT_METHODS_UPDATE: {
      return {
        ...state,
        methods: action.payload,
      }
    }

    case types.DISPLAY_THANK_YOU: {
      return {
        ...state,
        thankyou: true,
      }
    }

    case types.HIDE_THANK_YOU: {
      return {
        ...state,
        thankyou: false,
      }
    }

    default: return state
  }
}

export default paymentReducer