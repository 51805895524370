import logger from 'redux-logger';
import thunk from 'redux-thunk';
import {
  createStore,
  applyMiddleware
} from 'redux';
import rootReducer from 'Redux/reducers';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
  timeout: null,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
let middleware = {};

if (process.env.REACT_APP_ENV === "prod")
  middleware = applyMiddleware(thunk);
else
  middleware = applyMiddleware(thunk, logger);

export let store = createStore(persistedReducer, middleware)
export let persistor = persistStore(store)

