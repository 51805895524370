// reducers.js
import types from 'Redux/types';

const INITIAL_STATE = {
  data : {},
}

const categoryReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case types.CATEGORIES_SAVE: {
      return {
        ...state,
        data: action.data,
      }
    }

    default: return state;
  }
}


export default categoryReducer;