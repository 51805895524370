import React from 'react'

class SearchOn extends React.Component
{
render()
{
    return (
    <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg" >

        <g id="Icons-&amp;-Guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Icons" transform="translate(-308.000000, -180.000000)">
                <g id="icon/tab-bar/search-on" transform="translate(305.000000, 177.000000)">
                    <rect id="bounding-shape" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="32" height="32"></rect>
                    <path d="M20.6942459,21.4007399 C18.9216438,23.0053121 16.5706261,23.982669 13.9913345,23.982669 C8.47327282,23.982669 4,19.5093961 4,13.9913345 C4,8.47327282 8.47327282,4 13.9913345,4 C19.5093961,4 23.982669,8.47327282 23.982669,13.9913345 C23.982669,16.5706261 23.0053121,18.9216438 21.4007399,20.6942459 L28,27.293506 L27.293506,28 L20.6942459,21.4007399 Z M13.9913345,22.9835355 C18.95759,22.9835355 22.9835355,18.95759 22.9835355,13.9913345 C22.9835355,9.02507898 18.95759,4.99913345 13.9913345,4.99913345 C9.02507898,4.99913345 4.99913345,9.02507898 4.99913345,13.9913345 C4.99913345,18.95759 9.02507898,22.9835355 13.9913345,22.9835355 Z" stroke="#0E0E0E" strokeWidth="1.8" fill="#0E0E0E"></path>
                </g>
            </g>
        </g>
    </svg>

)
}
}

export default SearchOn;