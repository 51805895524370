import React from 'react'

class BookmarkOff extends React.Component
{
render()
{
    return (

      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#0E0E0E"
        strokeWidth="1.2"
        d="M11.613 14.215L5.1 19.71V4.6h13.8v15.109l-6.513-5.494L12 13.89l-.387.326z"
      ></path>
    </svg>

)
}
}

export default BookmarkOff;