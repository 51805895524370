import React from 'react';
import axios from 'axios';
import config from 'config';
import { withRouter } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import 'react-intl-tel-input/dist/main.css';
import { store } from '../../../Redux/store';
import actions from 'Redux/actions'

import { AppLink } from '../../App/Link/Link'
import ButtonLoader from 'Components/Loader/ButtonLoader'


class Register extends React.Component {
  constructor(props) {
    super(props)


    this.state = {
      email: '',
      password: '',
      responseReceived: false,
      loading: false,
      errors: {},
      exists: false,
      button_active: false
    }

    this.register = this.register.bind(this);
    this.update = this.update.bind(this);
    this.submit = this.submit.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.validator = new SimpleReactValidator();
  }

  register() {
    const component = this
    this.setState({ loading: true })

    axios.post(config.API_REGISTER_URL, {
      email: this.state.email,
      password: this.state.password
    })
      .then(function (response) {
        if (response.status === 200) {
          component.setState({ responseReceived: true, loading: false })
        }

      })
      .catch(function (error) {
        if (error.response.data.errors) {
          component.setState({ errors: error.response.data.errors, loading: false })
        }
      });
  }

  update(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onFocus(e) {
    this.setState({ errors: { ...this.state.errors, [e.target.name]: '' } });
  }

  componentDidMount() {

  }

  submit(e) {
    e.preventDefault();

    if (this.validator.allValid()) {
      this.register();
    } else {
      this.setState({ errors: this.validator.getErrorMessages() });
      this.forceUpdate();
    }
  }

  render() {
    const { responseReceived, errors, loading } = this.state

    this.validator.message('email', this.state.email, 'required|email')
    this.validator.message('password', this.state.password, 'required|min:6')

    return (
      <div> 
        <h1>Sign up here <span role="img" aria-label="point down">👇</span><br />️</h1>
        {responseReceived === false &&
          <form onSubmit={this.submit}>
            <div className="form-group signup email">
              <input className={"form-control " + (errors.email && 'is-invalid')} id="email" name="email" type="email" onFocus={this.onFocus} placeholder="Email" onChange={this.update} />
              <div className="invalid-feedback">{errors.email}</div>
            </div>

            <div className="form-group signup">
              <input className={"form-control " + (errors.password && 'is-invalid')} id="password" name="password" type="password" onFocus={this.onFocus} placeholder="Password" onChange={this.update} />
              <div className="invalid-feedback">{errors.password}</div>
            </div>

            <button className="btn-primary btn" disabled={loading}>
              {
                loading
                  ? <ButtonLoader />
                  : <span>Sign up</span>
              }
            </button>

            <div className="grey center terms">By signing up, you agree to our <AppLink onClick={() => store.dispatch(actions.hideOverlay())} to="/page/terms-and-conditions">Terms of Service</AppLink> and <AppLink onClick={() => store.dispatch(actions.hideOverlay())} to="/page/privacy">Privacy Policy</AppLink></div>
            <div className="grey center">Have an account? <AppLink overlay="login" to="/login">Log in</AppLink></div>

          </form>}
        {
          responseReceived && <div className="center"><span>Thank you! Check your email and click on the button inside to complete your sign up.</span></div>
        }
      </div>
    );
  }
}

export default withRouter(Register);