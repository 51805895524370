// reducers.js
import types from 'Redux/types';
import { Auth } from '../../Services/Auth';
//import { store } from '../store';

const INITIAL_STATE = {
  auth: false,
  token: '',
  user: {},
}

const authReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case types.AUTHENTICATE: {

      Auth.setToken(action.payload);
      return {
        ...state,
        auth: true,
        token: action.payload
      }
    }

    case types.PHONE_NUMBER_SAVE: {
      return {
        ...state,
        phone_number: action.payload
      }
    }
    
    case types.USER_SET: {
      document.body.classList.remove("hidden");
      return {
        ...state,
       user: action.payload
      }
    }
 
    default: return state;
  }
}

export default authReducer;