import React from 'react'

class BookmarkBigOn extends React.Component
{
render()
{
    return (

        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
        viewBox="0 0 32 32"
      >
        <path fill="#fff" fillOpacity="0.01" d="M0 0h32v32H0V0z"></path>
        <path fill="#fff" fillOpacity="0.01" d="M0 0H32V32H0z"></path>
        <path
          fill="#0E0E0E"
          fillRule="evenodd"
          stroke="#0E0E0E"
          strokeWidth="0.3"
          d="M5 4h21v24l-10.5-9L5 28V7 4z"
          clipRule="evenodd"
        ></path>
      </svg>

)
}
}

export default BookmarkBigOn;