import React from 'react'

class HomeOn extends React.Component
{
render()
{
    return (
  <svg width="25px" height="26px" viewBox="0 0 25 26" version="1.1" xmlns="http://www.w3.org/2000/svg" >
  
    <g id="Icons-&amp;-Guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-1332.000000, -52.000000)">
            <g id="icon/tab-bar/home-off" transform="translate(1329.000000, 49.000000)">
                <rect id="bounding-shape" fillOpacity="0" fill="#000000" x="0" y="0" width="32" height="32"></rect>
                <path d="M19,28 L19,21.5 C19,19.5670034 17.4329966,18 15.5,18 C13.5670034,18 12,19.5670034 12,21.5 L12,28 L4,28 L4,13 L15.5,4 L27,13 L27,28 L19,28 Z M20,27 L26,27 L26,13.4872245 L15.5,5.26983321 L5,13.4872245 L5,27 L11,27 L11,21.5 C11,19.0147186 13.0147186,17 15.5,17 C17.9852814,17 20,19.0147186 20,21.5 L20,27 Z" stroke="#0E0E0E" strokeWidth="0.3" fill="#0E0E0E"></path>
            </g>
        </g>
    </g>
</svg>

)
}
}

export default HomeOn;