import React from 'react'

class Logo extends React.Component
{
render()
{
    return (

<svg width="66" height="18" viewBox="0 0 66 18" xmlns="http://www.w3.org/2000/svg">
    <g id="Home" fill="none" fillRule="evenodd">
        <g id="Home---default" transform="translate(-51 -15)" fill="#FFF" fillRule="nonzero">
            <g id="Molecule-/-Header-/-Default">
                <g id="Header">
                    <g id="img_logo" transform="translate(51 14.5)">
                        <polygon id="Path" points="0 1.5 5.00990099 1.5 6.71782178 11.4428571 6.94554455 11.4428571 8.76732673 1.5 14.2326733 1.5 16.0544554 11.4428571 16.2821782 11.4428571 17.990099 1.5 23 1.5 19.6980198 17.5 13.4356436 17.5 11.6138614 8.01428571 11.3861386 8.01428571 9.56435644 17.5 3.3019802 17.5"
                        />
                        <path d="M24,9.5 C24,4.51351351 27.7822785,0.5 33,0.5 C38.2177215,0.5 42,4.51351351 42,9.5 C42,14.4864865 38.2177215,18.5 33,18.5 C27.7822785,18.5 24,14.4864865 24,9.5 Z M29.3544304,9.5 C29.3544304,11.9324324 30.9493671,13.6351351 33,13.6351351 C35.0506329,13.6351351 36.6455696,11.9324324 36.6455696,9.5 C36.6455696,7.06756757 35.0506329,5.36486486 33,5.36486486 C30.9493671,5.36486486 29.3544304,7.06756757 29.3544304,9.5 Z"
                        id="Combined-Shape" />
                        <polygon id="Path" points="43 1.5 48.009901 1.5 49.7178218 11.4428571 49.9455446 11.4428571 51.7673267 1.5 57.2326733 1.5 59.0544554 11.4428571 59.2821782 11.4428571 60.990099 1.5 66 1.5 62.6980198 17.5 56.4356436 17.5 54.6138614 8.01428571 54.3861386 8.01428571 52.5643564 17.5 46.3019802 17.5"
                        />
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

    )
}
}

export default Logo;