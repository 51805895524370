import React from 'react'

import { Auth } from 'Services/Auth'
import { Redirect } from 'react-router-dom'
import { persistor } from 'Redux/store'

class Logout extends React.Component
{
  constructor(props)
  {
    super( props );
    Auth.logout();
    persistor.purge();
  }

  render()
  {

    return <Redirect to="/" />
  }
}

export default Logout;