// reducers.js
import types from 'Redux/types';

const INITIAL_STATE = {
  trends: [],
  recent: [],
  visited: [],
}

const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SEARCH_HINTS_SAVE: {
      return {
        ...state,
        trends: action.payload.trending,
        recent: action.payload.recent.reverse(),
        visited: action.payload.visited
      }
    }

    case types.VISITED_ADD: {

      let shiftedVisited = state.visited

      if (!Array.isArray(shiftedVisited))
        return { ...state, visited: [action.payload] }

      if (shiftedVisited.find(x => x.id === action.payload.id))
        return { ...state }

      if (shiftedVisited.length >= 30)
        shiftedVisited.pop()

      shiftedVisited.unshift(action.payload)

      return {
        ...state,
        visited: shiftedVisited
      }
    }

    case types.SEARCH_OPEN: {
      return {
        ...state,
        open: true,
      }
    }

    case types.SEARCH_CLOSE: {
      return {
        ...state,
        open: false,
      }
    }

    default: return state;
  }
}


export default searchReducer;