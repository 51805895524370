import React from 'react';
import axios from 'axios';
import Product from './Product';
import Loader from 'Components/Loader'
import { Auth } from 'Services/Auth'

import config from 'config';

import './NewProducts.scss'

class NewProducts extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            products: [],
            loading: false,
        }

        this.loadNewProducts = this.loadNewProducts.bind(this)
    }

    loadNewProducts() {

        const self = this
        self.setState({ loading: true })

        axios.get(config.API_NEW_PRODUCTS, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {
                self.setState({ products: response.data, loading: false })
            })
    }

    componentDidMount() {
        this.loadNewProducts();
    }

    render() {
        const { products, loading } = this.state
        if (loading)
            return <Loader />
        else
            return (
                <div className={"list new-products " + (this.props.auth ? 'first' : '')}><h2>New arrivals</h2>
                    <div className="row">
                        <div className="products">
                            {products.length && products.map((item, index) =>

                                <Product className="col-6" key={index} data={item} />

                            )}
                        </div>
                    </div>
                </div >
            );
    }
}

export default NewProducts;
