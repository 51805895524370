import React from 'react'

class ShareWhatsapp extends React.Component
{
render()
{
    return (

        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          fill="#0E0E0E"
          fillOpacity="0.75"
          fillRule="evenodd"
          d="M8.997 0C4.036 0 0 4.037 0 9c0 1.968.635 3.793 1.714 5.275L.592 17.62l3.46-1.106A8.928 8.928 0 009.002 18C13.965 18 18 13.963 18 9s-4.036-9-8.997-9h-.006zM6.485 4.572c-.175-.418-.307-.434-.571-.445-.09-.005-.19-.01-.302-.01-.344 0-.703.1-.92.322-.265.27-.92.9-.92 2.19 0 1.292.94 2.54 1.068 2.715.132.175 1.835 2.862 4.48 3.958 2.068.857 2.682.777 3.152.677.688-.148 1.55-.656 1.767-1.27.217-.614.217-1.138.154-1.249-.064-.11-.239-.174-.503-.307-.264-.132-1.55-.767-1.793-.851-.238-.09-.465-.059-.645.196-.254.354-.503.714-.704.93-.159.17-.418.191-.635.101-.29-.121-1.105-.407-2.11-1.302-.778-.693-1.307-1.555-1.46-1.814-.153-.265-.016-.418.106-.56.132-.165.259-.281.391-.435.132-.153.206-.233.291-.413.09-.174.027-.354-.037-.486-.063-.133-.592-1.424-.81-1.947z"
          clipRule="evenodd"
        ></path>
      </svg>
)
}
}

export default ShareWhatsapp;