import React from 'react';
import axios from 'axios';
import config from 'config';
import {Redirect, withRouter} from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import 'react-intl-tel-input/dist/main.css';
import { store } from 'Redux/store';
import { Auth } from 'Services/Auth'
import  AppLink  from 'Components/App/Link/Link'
import './../../index.scss'
import ButtonLoader from 'Components/Loader/ButtonLoader'

//import actions from 'Redux/actions'

class EmailUpdate extends React.Component {
  constructor(props) {
    super(props)

    const { auth } = store.getState()

    this.state = {
      email: auth.user.email,
      phone_number: '',
      responseReceived: false,
      errors: {},
      exists: false,
      clicked: false
    }

    this.sendResetLink = this.sendResetLink.bind(this);
    this.update = this.update.bind(this);
    this.submit = this.submit.bind(this);

    this.validator = new SimpleReactValidator();
  }

  sendResetLink() {
    const component = this

    const { auth } = store.getState()

    axios.post(config.API_EMAIL_UPDATE_URL, {
      email: auth.user.email,
      new_email: this.state.email,
    }, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
      .then(function (response) {
       
        component.setState({ responseReceived: true, errors: {} })
      })
      .catch(function (error) {

        component.setState({responseReceived: true, errors: error.response.data.errors})
      });
  }

  update(e) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  }

  componentDidMount() {
    const { state } = this.props.location
    if (state)
      this.setState({ errors: { ...this.state.errors, phone_number: state.error } })
  }

  submit(e) {
    e.preventDefault();
    this.setState({clicked: true});
    if (this.validator.allValid()) {
      if (store.getState().auth.user.email === this.state.email) {
        this.setState({ errors: { email: 'Email provided is the same as currently used.' } });
        this.forceUpdate();

        setTimeout(function () {
          this.setState({clicked: false});
        }.bind(this), 200)
      }
      else 
        this.sendResetLink();
      
    } else {
      this.setState({ errors: this.validator.getErrorMessages() });
      this.forceUpdate();

      setTimeout(function () {
        this.setState({clicked: false});
      }.bind(this), 1000)
    }

  }

  render() {
    const { responseReceived, errors } = this.state
    this.validator.message('email', this.state.email, 'required|email')

    if (!store.getState().auth.auth)
      return <Redirect to="/profile"/>

    return (
      <div>
     {
     (responseReceived === false || (responseReceived === true && errors && errors.email)) ?
          <form onSubmit={this.submit} className="profile-edit">
            
            <div className="h1-container">
            <h1 className="h1-header">
            <AppLink to="/profile" className="cancel">Cancel</AppLink>
              Email
              {(this.state.clicked && !responseReceived) ?  
              <ButtonLoader />
              :
              <button className="btn-primary btn">
                Next
               </button>
              }  
            </h1>
            </div>
            
            <div className="forgotten">

              {/*<div className="form-group signup-error">
                <div className={errors.email ? 'invalid-feedback visible' : 'invalid-feedback'}>{errors.email}</div>
        </div>*/}

              <div className="form-group email-update">
                <input className={"form-control " + (errors.email && 'is-invalid')} id="email" type="text" name="email" defaultValue={this.state.email} placeholder="Email" onChange={this.update} />
                <div className="invalid-feedback email-error">{errors.email}</div>
              </div>

            </div>
          </form>
        :
          <div className="settings-container">
          <div className="row settings-header">
            <div className="col-3"></div>
            <div className="col-6"><h1>Confirmation</h1></div>
            <div className="col-3"><AppLink to="/profile" className="continue">Continue</AppLink></div>  
          </div>
          
          <div className="product-container text">We sent an email to <span>{this.state.email}</span> with a confirmation link. Please click on it to confirm the new email address.</div>
          </div>
        }
      
      </div>
    );
  }
}

export default withRouter(EmailUpdate);