import React from 'react'

class CategoriesOn extends React.Component
{
render()
{
    return (

<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Icons-&amp;-Guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-1204.000000, -52.000000)">
            <g id="icon/tab-bar/categories-off" transform="translate(1201.000000, 49.000000)">
                <rect id="bounding-shape" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="32" height="32"></rect>
                <path d="M6,4 L15,4 L15,15 L4,15 L4,6 C4,4.8954305 4.8954305,4 6,4 Z M4,17 L15,17 L15,28 L6,28 C4.8954305,28 4,27.1045695 4,26 L4,17 Z M17,4 L26,4 C27.1045695,4 28,4.8954305 28,6 L28,15 L17,15 L17,4 Z M17,17 L28,17 L28,26 C28,27.1045695 27.1045695,28 26,28 L17,28 L17,17 Z" id="Combined-Shape" stroke="#0E0E0E" strokeWidth="0.3" fill="#0E0E0E"></path>
            </g>
        </g>
    </g>
</svg>

)
}
}

export default CategoriesOn;