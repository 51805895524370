// reducers.js
import types from 'Redux/types';

const INITIAL_STATE = {
  data: []
}

const bookmarkReducer = (state = INITIAL_STATE, action) => {

  switch(action.type) {

    case types.BOOKMARKS_SET: {
      return {
        ...state,
        data:action.payload,
      }
    }
 
    default: return state;
  }
  
}

export default bookmarkReducer;