import React from 'react'

class HomeOn extends React.Component
{
render()
{
    return (
   <svg width="25px" height="26px" viewBox="0 0 25 26" version="1.1" xmlns="http://www.w3.org/2000/svg" >

    <g id="Icons-&amp;-Guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-52.000000, -180.000000)">
            <g id="icon/tab-bar/home-on" transform="translate(49.000000, 177.000000)">
                <rect id="bounding-shape" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="32" height="32"></rect>
                <path d="M19,28 L19,21.5 C19,19.5670034 17.4329966,18 15.5,18 C13.5670034,18 12,19.5670034 12,21.5 L12,28 L4,28 L4,13 L15.5,4 L27,13 L27,28 L19,28 Z" stroke="#0E0E0E" strokeWidth="0.3" fill="#0E0E0E"></path>
            </g>
        </g>
    </g>
</svg>

)
}
}

export default HomeOn;