import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { store } from 'Redux/store'
import actions from 'Redux/actions'


export class AppLink extends React.Component {

  constructor(props) {
    super(props);
    this.handleAuthClick = this.handleAuthClick.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  handleAuthClick(e) {
    e.preventDefault()
    store.dispatch(actions.showOverlay({ content: "login" }))

    if(this.props.menu)
      this.props.onClick();
  }

  componentWillMount() {

    this.unsubscribe = store.subscribe(() => {
      this.forceUpdate()
    });

  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  handleClick(e) {

    if (this.props.overlay) {
      if (this.props.to !== "/") {
        e.preventDefault()
        e.stopPropagation()
      }
      store.dispatch(actions.showOverlay({ content: this.props.overlay }))
    }
  }

  render() {
    const { auth } = store.getState()
    const { staticContext, ...rest } = this.props;
    if (this.props.authonly === "true" && !auth.auth)
      return (<Link {...rest} onClick={this.handleAuthClick} >{this.props.children}</Link>)
    else
      return (<Link onClick={this.handleClick} {...rest}>{this.props.children}</Link>)
  }
}



export default withRouter(AppLink);
