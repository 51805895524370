import React from 'react'

class ShareWhatsapp extends React.Component
{
render()
{
    return (

        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="15"
        fill="none"
        viewBox="0 0 20 15"
      >
        <path
          fill="#0E0E0E"
          fillOpacity="0.75"
          d="M18.933.406l-8.29 6.717a1.068 1.068 0 01-1.286 0L1.067.406A1.65 1.65 0 012.152 0h15.694c.416 0 .797.153 1.087.406zm.567 1.689v10.3a1.649 1.649 0 01-1.652 1.649H2.152A1.65 1.65 0 01.5 12.395v-10.3l8.215 6.656c.71.575 1.862.574 2.57 0L19.5 2.095z"
        ></path>
      </svg>
)
}
}

export default ShareWhatsapp;