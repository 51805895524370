import React from 'react'
import axios from 'axios'
import config from 'config';
import { store } from 'Redux/store';
import Moment from 'react-moment'
import  AppLink  from 'Components/App/Link/Link'
import Product from '../Product/Product'
import Loader from 'Components/Loader'

import { Auth } from 'Services/Auth'
import './index.scss'
import '../Product/Product.scss'
import '../Product/SingleProduct.scss'

class SingleOrder extends React.Component {
    constructor(props) {

        super(props)
        this.state = {
            data: {},
            related: []
        }

        this.loadOrder = this.loadOrder.bind(this)

    }

    componentWillMount() {
        this.unsubscribe = store.subscribe(() => {
            this.forceUpdate()
        })

        this.loadOrder()
    }

    loadOrder() {

        let component = this
        axios.get(config.API_ORDER_URL + this.props.match.params.id, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {
                component.setState({
                    data: response.data,
                })

                axios.get(config.API_RELATED_PRODUCTS_URL + response.data.line_items[0].product_id, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
                    .then(function (response) {
                        component.setState({
                            related: response.data.slice(1).sort(() => Math.random() - 0.5)
                        })
                    });

            });
    }

    render() {

        const { data, related } = this.state
        const shipping = data.shipping_address
        const fulfillments = data.fulfillments
        // let tracking_number
        let tracking_url 

        if (fulfillments && fulfillments[0] && fulfillments[0].tracking_urls && fulfillments[0].tracking_urls.length)
            tracking_url = fulfillments[0].tracking_urls[0]

        var payment = null;
        if (data.payments && data.payments[0]) {
            payment = data.payments[0];
        }

        var toCamelCase = function (value) {
            return value.substring(0, 1).toUpperCase() + value.substring(1);
        };

        return (


            <div>

                <div className="order-container single-order">
                    <h1>

                        <AppLink to="/orders" className="back"> </AppLink>

                        Order details</h1>
                    {!data.id && <Loader />}
                    {data && data.id && data.line_items &&  <div>
                        {
                            data.line_items.map((item, i) => (
                                <div key={i}>
                                    <div className="img">
                                        {<AppLink to={"/product/" + data.line_items[0].product_id}>{data.image && <img src={data.image.src} alt="" />}
                                        </AppLink>}
                                    </div>
                                    <div className="details">
                                        <h1>{data.product && data.product.title}</h1>
                                        <span className="price">${item.data.price}</span>
                                        <ul>
                                            <li>Quantity: {item.quantity}</li>
                                            {item.data.title && <li>Options: {item.data.title}</li>}
                                        </ul>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            ))
                        }

                        <ul className="details-ul">
                            <li className="line"><div className="desc">Order Number</div> <div className="val">{data.name}</div><div className="clearfix"></div></li>
                            <li><div className="desc">Order Date</div> <div className="val"><Moment format="MMMM D, YYYY" date={data.created_at} /></div><div className="clearfix"></div></li>
                        </ul>

                        <div className="thick-hr before-shipment"></div>


                        <ul className="details-ul">

                            <li className="line"><div className="desc">Shipment Status</div><div className="val">{tracking_url ? 'shipped' : 'awaiting'}</div><div className="clearfix"></div></li>

                            {shipping &&
                                <li className="line"><div className="desc">Shipping Address</div> <div className="val shipping-details">
                                    <span>
                                        {shipping.first_name}{' '}
                                        {shipping.last_name}<br />
                                        {shipping.address1}<br />
                                        {shipping.city}&nbsp;{shipping.province}<br />
                                        {shipping.zip}<br />
                                        {shipping.country && shipping.country.replace('US', 'United States')}
                                    </span></div><div className="clearfix"></div></li>
                            }
                            { /*<li><div className="desc">Payment Method</div> <div className="val"> {data.name}</div><div className="clearfix"></div></li>*/}

                        </ul>

                        {payment && 
                            <ul className="details-ul subtotal">
                                <li><div className="desc">Payment Method</div> <div className="val shipping-details">{payment.brand ? toCamelCase(payment.brand) : 'other'} ****{payment.last4 ? payment.last4 : ''}</div><div className="clearfix"></div></li>
                                {/*<li><div className="desc"></div> <div className="val light">{payment.created_at} </div><div className="clearfix"></div></li>*/}
                            </ul>}

                        <div className="thick-hr before-subtotal"></div>

                        <ul className="details-ul subtotal">
                            <li><div className="desc">Subtotal</div> <div className="val light">${data.subtotal_price}</div><div className="clearfix"></div></li>
                            <li><div className="desc light">Shipping</div> <div className="val light">free</div><div className="clearfix"></div></li>
                            <li><div className="desc dark">Total</div> <div className="val"> ${data.total_price}</div><div className="clearfix"></div></li>
                        </ul>

                        <div className="thick-hr"></div>

                    </div>}



                </div>


                <div className="product-container related">

                    <div className="product-inner">
                        {related.length>0 &&
                            <>
                                <h1>Related products</h1>
                                <div className="products">
                                    <div className="inproducts related">
                                        {related.length>0 && related.map((value, index) => {
                                            return <Product className="col-6" key={index} data={value} />
                                        })}
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </div>

            </div>



        )
    }
}

export default SingleOrder;