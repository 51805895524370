import React from 'react';
import { injectStripe, PaymentRequestButtonElement } from 'react-stripe-elements';
import axios from 'axios'

import { Auth } from 'Services/Auth'
import { Order } from 'Services/Order'

import config from 'config'

import actions from 'Redux/actions'
import { store } from 'Redux/store';
import { Redirect } from 'react-router-dom'


import './index.scss'

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.handlePaymentSuccess = this.handlePaymentSuccess.bind(this)
    this.handlePaymentError = this.handlePaymentError.bind(this)

    this.state = {
      client_secret: {},
      payment_intent: {},
      customer_information: {},
      error: {},
      succeeded: {}
    }

    const paymentRequest = props.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: "Wow",
        amount: Math.round(this.props.selectedVariant.price * 100),
      },
      requestShipping: true,
      shippingOptions: [
        {
          id: 'free-shipping',
          label: 'Free shipping. We ship to the US only',
          detail: 'Arrives in 15 to 30 days',
          amount: 0,
        },
      ],
    });

    var isShippingAddressValid = function(shippingAddress) {
      var isValid = true;

      if(!shippingAddress.postalCode.match(/[0-9]{5}/)) {
        isValid = false;
      }

      if (shippingAddress.country !== 'US') {
        isValid = false;
      }

      return isValid;
    }

    paymentRequest.on('source', (result) => {
      let component = this;
      const { source, error, complete } = result;
      if (error) {
        this.handlePaymentError(error);
      }

      this.props.stripe.confirmPaymentIntent(component.state.payment_intent.client_secret, {
        source: source.id,
        use_stripe_sdk: true,
      }).then(function (confirmResult) {
        if (confirmResult.error) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error error and close
          // the payment interface.
          complete('fail');
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          complete('success');

          // Payment already completed
          if (confirmResult.paymentIntent.status === 'succeeded') {
            component.handlePaymentSuccess();
            return;
          }

          // 3D Secure required let stripe handle that
          component.props.stripe.handleCardPayment(
            component.state.payment_intent.client_secret,
            {
              source: source.id,
            }
          ).then(function (result) {
            // 3D Secure completed
            if (result.error) {

              component.handlePaymentError(result.error);
            } else {
              component.handlePaymentSuccess();
            }
          });
        }
      });


    })

    paymentRequest.on('token', ({ complete, token, ...data }) => {

      if(!isShippingAddressValid(data.shippingAddress)) {
        complete('invalid_shipping_address');
        return;
      }

      this.setState({ customer_information: data });
    });

    paymentRequest.on('shippingaddresschange', function(ev) {
      
      if(isShippingAddressValid(ev.shippingAddress)) {
        ev.updateWith({status: 'success'});
      } else {
        ev.updateWith({status: 'invalid_shipping_address'});
      }
    });

    paymentRequest.canMakePayment().then((result) => {
      let component = this;
      this.setState({ canMakePayment: !!result })

      let methods = {}

      methods.card = true
      if (result) {
        methods.apppay = true
        axios.post(config.API_PAYMENT_INTENT_URL, { amount: this.props.selectedVariant.price * 100 }, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
          .then(function (response) {
            component.setState({ payment_intent: response.data })

          });
      }
      else
        methods.apppay = false

      store.dispatch(actions.updatePaymentMethods(methods))
    });

    this.state = {
      canMakePayment: 'unknown',
      paymentRequest,
      cardElement: false,
      paymentSending: false,
    };

    this.handleClick = this.handleClick.bind(this)
    this.paymentButtonAvailable = this.paymentButtonAvailable.bind(this)
  }

  handlePaymentError(error) {
    this.setState({
      paymentSending: false,
      succeeded: false,
      error: error,
      details: {},
    })
  }


  componentDidUpdate(prevProps) {


    if (this.state.payment_intent && prevProps.selectedVariant && prevProps.selectedVariant.price !== this.props.selectedVariant.price) {
      let component = this
      this.setState({ buttonDisabled: true })

      axios.post(config.API_PAYMENT_INTENT_UPDATE_URL, {
        amount: this.props.selectedVariant.price * 100,
        payment_intent_id: this.state.payment_intent.id
      }, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
        .then(function (response) {
          component.setState({ payment_intent: response.data, buttonDisabled: false })
        });
    }

  }


  handlePaymentSuccess() {
    let component = this;
    axios.post(config.API_CREATE_ORDER_URL,
      Order.createAppPay(component.props.selectedVariant, component.state.customer_information.shippingAddress, component.state.payment_intent.id),
      { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
      .then(function (response) {

        if (response.data.order.id) {
          store.dispatch(actions.saveLastOrder(response.data.order))
          component.setState({
            paymentSending: false,
            succeeded: true,
            error: {},
            // details: result,
          })

        }

      })
  }

  handleClick() {
    this.props.onCardSelected()
  }

  paymentButtonAvailable() {
    return (
      this.state.canMakePayment &&
      this.state.canMakePayment !== "unknown"
      && this.props.preSelected !== "card")
  }

  render() {
    if (this.state.succeeded)
      return <Redirect to={"/thankyou/" + this.props.product.id} />
    else
    if(this.props.selectedVariant.inventory_quantity > 0)
      return (
        <div>
          {this.state.error && <div className="error">{this.state.error.message}</div>}
          <div className="payment_request">
            {this.paymentButtonAvailable() &&
              <PaymentRequestButtonElement
                paymentRequest={this.state.paymentRequest}
                className="PaymentRequestButton"
                style={{
                  paymentRequestButton: {
                    theme: 'dark',
                    height: '48px',
                  },
                }}
              />}
            {(this.state.canMakePayment === false || this.props.preSelected === 'card') &&
              <button onClick={this.handleClick}>Pay by Card</button>
            }
          </div>
        </div>
      )
     else
     {
       return (
         <div>
           <button disabled={true}>Pay by Card</button>
         </div>
       )
     } 
  }
}
export default injectStripe(Checkout);