 
const domain = window.location.origin

  const dev = {
    API_URL: "http://localhost:8000",
    STRIPE_PUBLIC_KEY: "pk_test_z7NTCo4HxLTLd2XWgXmmCXRo00Gkbc3sTN",
    APP_ABSOLUTE_URL: "https://localhost:3000",
    CART_URL: "https://test.wowville.com/cart/"
  };

  const staging = {
    API_URL: "https://staging.wowville.com/api",
    STRIPE_PUBLIC_KEY: "pk_test_z7NTCo4HxLTLd2XWgXmmCXRo00Gkbc3sTN",
    APP_ABSOLUTE_URL: "https://staging.wowville.com",
    CART_URL: "https://test.wowville.com/cart/"
  };
  
  const prod = {
    API_URL: "https://api.wowville.com",
    STRIPE_PUBLIC_KEY: "pk_live_lvybzwc0xcO5vujy81Gu8faX00KO3OEZs7",
    APP_ABSOLUTE_URL: "https://wowville.com",
    CART_URL: "https://checkout.wowville.com/cart/"
  };

  const self = {
    API_URL: domain + "/api",
    STRIPE_PUBLIC_KEY: "pk_test_z7NTCo4HxLTLd2XWgXmmCXRo00Gkbc3sTN",
    APP_ABSOLUTE_URL: domain,
    CART_URL: "https://test.wowville.com/cart/"
  }

  const kpro = {
    API_URL: "https://dwow-api.localhost",
    STRIPE_PUBLIC_KEY: "pk_test_z7NTCo4HxLTLd2XWgXmmCXRo00Gkbc3sTN",
    APP_ABSOLUTE_URL: domain,
    CART_URL: "https://checkout.wowville.com/cart/"
  }

  let config = {}
  
  switch(process.env.REACT_APP_ENV)
  {
      case 'prod':
        config = prod; break;
      case 'staging':
        config = staging; break;
      case 'development':
        config = dev; break;
      case 'kpro':
        config = kpro; break;
      default:
        config = self; break;  
  }

  
  export default {

    API_ME : config.API_URL + '/auth/me',
    API_PRODUCTS_URL : config.API_URL + '/shopify/products',
    API_NEW_PRODUCTS : config.API_URL + '/products/new',
    API_RELATED_PRODUCTS_URL : config.API_URL + '/related_products/',
    API_PRODUCT_URL : config.API_URL + '/shopify/product/',
    API_REGISTER_URL : config.API_URL + '/auth/register',
    API_VERIFY_URL : config.API_URL + '/auth/verify',
    API_VERIFY_EMAIL_URL : config.API_URL + '/verify-email',
    API_SETUP_URL : config.API_URL + '/auth/setup',
    API_LOGIN_URL : config.API_URL + '/auth/login',
    API_BOOKMARK_URL : config.API_URL + '/bookmarks',
    API_PAYMENT_INTENT_URL : config.API_URL + '/stripe/intent',
    API_PAYMENT_INTENT_UPDATE_URL : config.API_URL + '/stripe/intent/update',
    API_COLLECTIONS_URL : config.API_URL + '/shopify/collections',
    API_SEARCH_URL : config.API_URL + '/search/',
    API_SEARCH_HINTS_URL : config.API_URL + '/search/hints',
    API_SEARCH_AUTO_SUGGEST : config.API_URL + '/search/autosuggest/',
    API_CREATE_ORDER_URL : config.API_URL + '/shopify/order',
    API_ORDER_URL : config.API_URL + '/shopify/order/',
    API_ORDERS_URL : config.API_URL + '/shopify/orders',
    API_PAGE_URL : config.API_URL + '/shopify/page',
    API_USER_URL : config.API_URL + '/shopify/customer/',
    API_FORGOTTEN_URL : config.API_URL + '/auth/forgotten',
    API_RESET_URL : config.API_URL + '/auth/reset',
    API_VERIFYTOKEN_URL : config.API_URL + '/auth/verifytoken',

    API_EMAIL_UPDATE_URL : config.API_URL + '/update/email',
    API_EMAIL_UPDATE_CONFIRM_URL : config.API_URL + '/update/email/confirm',
    API_PHONE_UPDATE_URL : config.API_URL + '/update/phone',
    API_PHONE_UPDATE_CONFIRM_URL : config.API_URL + '/update/phone/confirm',
    API_PASSWORD_UPDATE_URL : config.API_URL + '/update/password',

    API_PASSWORD_UPDATE_CONFIRM_URL : config.API_URL + '/update/password/confirm',
    API_GET_ADDRESSES_URL : config.API_URL + '/shopify/addresses',

    API_PROFILE_UPDATE_URL : config.API_URL + '/update/profile',
    API_PAGES_BY_TAG_URL : config.API_URL + '/shopify/articles',
    API_ARTICLE_URL : config.API_URL + '/shopify/article',
    API_JOIN_EMAIL_URL : config.API_URL + '/join',

    API_CLEAR_TESTS : config.API_URL + '/clear-tests',

    ...config
  };
