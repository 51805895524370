import React from 'react'

class CategoriesOff extends React.Component
{
render()
{
    return (
  <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Misc" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Bookmarks---empty---S" transform="translate(-100.000000, -740.000000)" fill="#0E0E0E" fillRule="nonzero">
            <g id="Molecules/Bottom-bar-Molecule-/Bottom-bar" transform="translate(0.000000, 728.000000)">
                <g id="Bottom-bar">
                    <g id="Atoms/Tab-bar/Background" transform="translate(75.000000, 0.000000)">
                        <g id="icon/tab-bar/home-off-icon/tab-bar/categories-off" transform="translate(22.000000, 9.000000)">
                            <path d="M6,3.85 L15,3.85 L15.15,4 L15.15,15 L15,15.15 L4,15.15 L3.85,15 L3.85,6 C3.85,4.81258779 4.81258779,3.85 6,3.85 Z M6,5.15 C5.53055796,5.15 5.15,5.53055796 5.15,6 L5.15,13.85 L13.85,13.85 L13.85,5.15 L6,5.15 Z M3.85,17 L4,16.85 L15,16.85 L15.15,17 L15.15,28 L15,28.15 L6,28.15 C4.81258779,28.15 3.85,27.1874122 3.85,26 L3.85,17 Z M5.15,26 C5.15,26.469442 5.53055796,26.85 6,26.85 L13.85,26.85 L13.85,18.15 L5.15,18.15 L5.15,26 Z M16.85,4 L17,3.85 L26,3.85 C27.1874122,3.85 28.15,4.81258779 28.15,6 L28.15,15 L28,15.15 L17,15.15 L16.85,15 L16.85,4 Z M18.15,13.85 L26.85,13.85 L26.85,6 C26.85,5.53055796 26.469442,5.15 26,5.15 L18.15,5.15 L18.15,13.85 Z M16.85,17 L17,16.85 L28,16.85 L28.15,17 L28.15,26 C28.15,27.1874122 27.1874122,28.15 26,28.15 L17,28.15 L16.85,28 L16.85,17 Z M18.15,26.85 L26,26.85 C26.469442,26.85 26.85,26.469442 26.85,26 L26.85,18.15 L18.15,18.15 L18.15,26.85 Z" id="Combined-Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

)
}
}

export default CategoriesOff;