import React from 'react';
import { store } from 'Redux/store'
import actions from 'Redux/actions'
import axios from 'axios';
import config from 'config';
import SimpleReactValidator from 'simple-react-validator'
import ButtonLoader from 'Components/Loader/ButtonLoader'

import './index.scss'

class JoinEmail extends React.Component {
  constructor(props) {
    super(props)


    this.state = {
      email: '',
      password: '',
      responseReceived: false,
      loading: false,
      errors: {},
    }

    this.joinEmail = this.joinEmail.bind(this);
    this.update = this.update.bind(this);
    this.submit = this.submit.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.validator = new SimpleReactValidator();
  }

  update(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onFocus(e) {
    this.setState({ errors: { ...this.state.errors, [e.target.name]: '' } });
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    if (this.state.responseReceived)
      store.dispatch(actions.setSubscribed())
  }

  submit(e) {
    e.preventDefault();

    if (this.validator.allValid()) {
      this.joinEmail();
    } else {
      this.setState({ errors: this.validator.getErrorMessages() });
      this.forceUpdate();
    }
  }

  joinEmail() {
    const component = this
    this.setState({ loading: true })

    axios.post(config.API_JOIN_EMAIL_URL, {
      email: this.state.email,
    })
      .then(function (response) {
        component.setState({ responseReceived: true, loading: false, email: '' })
        store.dispatch(actions.setNotification({ type: 'success', message: 'Thank you for subscribing' }))
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          component.setState({ errors: error.response.data.errors, loading: false })
        }
      });
  }

  render() {
    const { errors, loading } = this.state
    this.validator.message('email', this.state.email, 'required|email')

    return (
      <div className="join-email">
        <form onSubmit={this.submit}>
          <p>Get new products and discounts news.<br />No spam ever, unsubscribe anytime.</p>

          <div className="form-group signup email">
            <input className={"form-control " + (errors.email && 'is-invalid')} id="email" name="email" type="email" onFocus={this.onFocus} placeholder="Email" value={this.state.email} onChange={this.update} />
            <div className="invalid-feedback">{errors.email}</div>
          </div>

          <button className="btn-primary btn" disabled={loading}>
            {
              loading
                ? <ButtonLoader />
                : <span>Ok, I'm in!</span>
            }
          </button>

        </form>
      </div>
    );
  }
}

export default JoinEmail;