// reducers.js
import types from 'Redux/types';

const INITIAL_STATE = {
  data : {},
  page : 1,
  count: 0,
  loading: true,
  selectedVariant: {}
}

const productReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case types.PRODUCTS_ADD: {
      return {
        ...state,
        data: state.data.concat(action.products),
        count: action.count,
        page: action.page,
        lastpage : action.lastpage,
      }
    }

    case types.PRODUCTS_POPULAR_ADD: {
      return {
        ...state,
        popular: action.data,
      }
    }

    case types.SAVE_SCROLL_POSITION: {
      return {
        ...state,
        scrollPosition: action.payload,
      }
    }

    case types.PRODUCTS_INITIAL_ADD: {
      return {
        ...state,
        data: action.products,
        count: action.count,
        page: action.page,
        lastpage : action.lastpage,
      }
    }
    
    case types.PRODUCTS_CLEAN:
    {
      return {
        ...state,
        data: {}
      }
    }

    case types.PRODUCTS_LOADING: {
      return {
        ...state,
        loading: true
      }
    }
    case types.PRODUCTS_LOADED: {
      return {
        ...state,
        loading: false
      }
    }

    case types.SELECT_VARIANT: {
      return {
        ...state,
        selectedVariant: action.selectedVariant
      }
    }

    default: return state;
  }
}


export default productReducer;