import React from 'react';
import  AppLink  from 'Components/App/Link/Link'
import './index.scss'

class Order extends React.Component
{
    render()
    {
        const { data, className } = this.props;
        const item = data.line_items[0]

      
        return (
            <div className={"order " + className}>
              <AppLink to={item.title ? ("/order/" + data.id) : '#'}>
              <div className="img-container">
                <div className="img">{data.image && <img src={data.image.src} alt="" />}</div>
            </div>
              <div className="details">
              <h1>{item.title ? item.title : "Order archived"}</h1>
              <ul>
                  <li>{data.name}</li>
                  <li>${data.total_price}</li>
                  <li>Quantity: {item.quantity}</li>
                  {(item.data && item.variant_title)  ? <li>Option: {item.variant_title}</li> : <li>Order archived</li>}
              </ul>
              </div>
              

              <div className="clearfix"></div>
              </AppLink>
            </div>
        )
       } 
    
}

export default Order;