
import React from 'react';
import './index.scss'

class ButtonLoader extends React.Component {
    render() {
        return (
            <div className="ispinner">
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
            </div>
        )

    }
}

export default ButtonLoader;
