import React from 'react'

class ShareTwitter extends React.Component
{
render()
{
    return (

        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="17"
        fill="none"
        viewBox="0 0 21 17"
      >
        <path
          fill="#0E0E0E"
          fillOpacity="0.75"
          fillRule="evenodd"
          d="M20.4 2.013a8.175 8.175 0 01-2.403.675 4.282 4.282 0 001.84-2.374 8.243 8.243 0 01-2.66 1.04A4.127 4.127 0 0014.124 0C11.813 0 9.94 1.922 9.94 4.291c0 .337.035.663.107.978C6.57 5.089 3.486 3.383 1.42.783a4.37 4.37 0 00-.566 2.16 4.32 4.32 0 001.862 3.572A4.109 4.109 0 01.819 5.98v.053c0 2.08 1.443 3.815 3.359 4.208-.351.1-.721.15-1.103.15-.27 0-.533-.025-.788-.076.533 1.705 2.077 2.946 3.91 2.98A8.264 8.264 0 010 15.072 11.653 11.653 0 006.416 17c7.699 0 11.908-6.539 11.908-12.21 0-.187-.004-.373-.011-.556A8.589 8.589 0 0020.4 2.013z"
          clipRule="evenodd"
        ></path>
      </svg>

)
}
}

export default ShareTwitter;