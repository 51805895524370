import React from 'react'
import axios from 'axios'
import config from 'config';

import { Redirect } from 'react-router-dom'
import { store } from 'Redux/store'

import actions from 'Redux/actions';

class VerifyUpdateEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responseReceived: false,
      error: false,
    }
  }

  componentDidMount() {
    const { token } = (this.props.match.params)
    const component = this

    axios.post(config.API_EMAIL_UPDATE_CONFIRM_URL, {token: token})
      .then(function (response) {
        store.dispatch(actions.authenticate(response.data.access_token))
        store.dispatch(actions.setUser(response.data.user))
        store.dispatch(actions.setNotification({ type: 'success', message: 'Email has been updated' }))
        component.setState({ responseReceived: true })
        component.forceUpdate()
      })
      .catch(function(error){
          if (error.response.data.errors){
            component.setState({ error: error.response.data.errors })
          }
      });
  }




  render() {
    const { responseReceived } = this.state

    if (this.state.error)
      return (
         <div >
         
            <div className="product-container">
              {this.state.error.email}
            </div>
    
         </div>
      );
    else
      return (
        responseReceived && <Redirect to='/profile' />
      );
  }
}

export default VerifyUpdateEmail;