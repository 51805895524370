import React from 'react';
import App from 'Components/App';
import { Route, withRouter, Switch } from 'react-router-dom'
import Verify from 'Components/Verify'
import VerifyEmail from 'Components/VerifyEmail'
import VerifyToken from 'Components/VerifyToken'
import Thankyou from 'Components/Thankyou'
import Logout from 'Components/Logout'

import SingleProduct from 'Components/Product/SingleProduct'
import Algolia from 'Components/Product/Algolia'
import SingleOrder from 'Components/Order/SingleOrder'
import OrderList from 'Components/Order/OrderList'
import Settings from 'Components/Settings'
import Page from 'Components/Page'
import Article from 'Components/Page/Article'
import PageList from 'Components/Page/PageList'
import Profile from 'Components/Settings/Profile'
import EmailUpdate from 'Components/Settings/Profile/EmailUpdate'
import PhoneUpdate from 'Components/Settings/Profile/PhoneUpdate'
import VerifyUpdateEmail from 'Components/Settings/Profile/EmailUpdate/verify'
import VerifyUpdatePhone from 'Components/Settings/Profile/PhoneUpdate/verify'
import NameUpdate from 'Components/Settings/Profile/NameUpdate'
import PasswordUpdate from 'Components/Settings/Profile/PasswordUpdate'

import Categories from 'Components/Category'

import axios from 'axios'
import config from 'config'

import actions from 'Redux/actions';
import { store } from 'Redux/store';

import Header from 'Components/App/Header'
import Footer from 'Components/App/Footer'

import Page404 from 'Components/App/Page404'

import {Helmet} from "react-helmet";
import {StripeProvider} from 'react-stripe-elements';
import { Auth } from 'Services/Auth'

class Main extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showFooter: !this.isProduct(window.location.pathname),
      stripe: null
    }

    const oldUrl = store.getState().settings.url

    if(window.location.search.includes('from-checkout') && oldUrl)
    {
      setTimeout(function(){document.location.href = oldUrl;},250);
    }
  }

  componentWillMount() {
    axios.interceptors.response.use(null, function (data) {
      if (data && data.response && data.response.status === 401) {
        Auth.logout()
        store.dispatch(actions.showOverlay({ content: "login" }))
      }

      return Promise.reject(data);
    });

    store.dispatch(actions.updatePath())

    window.Beacon('on', 'ready', () => { this.toggleHelpScout() })

  

    this.props.history.listen((location, action) => {

      this.setState({ showFooter: !this.isProduct(location.pathname) })

      if (store.getState().search.open)
        store.dispatch(actions.closeSearch())

     
      store.dispatch(actions.updatePath())
      store.dispatch(actions.hideOverlay())

      this.toggleHelpScout()

    });

    if (window.performance) {
      store.dispatch(actions.hideOverlay())
      if (performance.navigation.type === 1) {
        store.dispatch(actions.cleanProducts())
        

      } else {

      }
    }

    window.onpageshow = function(event) {
      if (event.persisted) {
        window.location.reload();
      }
    };



   
  }

  toggleHelpScout() {
    const hs = document.querySelector('.BeaconFabButtonFrame')
    if (hs) {
      if (this.isArticle(window.location.pathname))
        hs.style.display = "block"
      else
        hs.style.display = "none"
    }
  }

  isProduct(location) {
    return location.startsWith("/product");
  }

  isArticle(location) {
    return location.startsWith("/article") || location.startsWith("/page/support");
  }

  componentDidMount() {
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(config.STRIPE_PUBLIC_KEY)});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({stripe: window.Stripe(config.STRIPE_PUBLIC_KEY)});
      });
    }
  }

  render() {

    return (
      <StripeProvider apiKey={config.STRIPE_PUBLIC_KEY} stripe={this.state.stripe}>
        <div className="app">

          <Helmet>
              <title>WOW</title>
              <meta name="twitter:title" content="WOW" />
              <meta property="og:title"  content="WOW" />
          </Helmet>

          <Header />
          <Switch>
            <Route exact={true} path="/" render={() => (<App />)} />
            <Route path="/verify/:code" component={Verify} />
            <Route path="/verify-email/:code" component={VerifyEmail} />
            <Route path="/verifytoken/:token" component={VerifyToken} />
            <Route exact={true} path="/reset/:token" render={(obj) => (<App overlay="reset" {...obj}  />)} />
            <Route path="/logout" component={Logout} />
            <Route exact={true} path="/register" render={() => (<App overlay="register" />)} />
            <Route exact={true} path="/login" render={() => (<App overlay="login" />)} />
            <Route exact={true} path="/forgotten" render={() => (<App overlay="forgotten" />)} />

            <Route name='shared' path="/product/:id" state={"XFG"} component={SingleProduct} />
            <Route path="/category/:category" component={App} />
            <Route path="/search/:phrase" component={App} />
            <Route path="/bookmarked" component={App} />
            <Route path="/categories" component={Categories} />
            <Route path="/thankyou/:id" component={Thankyou} />
            <Route path="/orders" component={OrderList} />
            <Route path="/order/:id" component={SingleOrder} />
            <Route path="/settings" component={Settings} />
            <Route path="/page/:page" component={Page} />
            <Route path="/article/:article" component={Article} />
            <Route path="/articles/:tag" component={PageList} />
            <Route path="/profile" exact={true} component={Profile} />
            <Route path="/algolia" exact={true} component={Algolia} />

            <Route path="/profile/email" exact={true} component={EmailUpdate} />
            <Route path="/profile/phone" exact={true} component={PhoneUpdate} />
            <Route path="/profile/password" exact={true} component={PasswordUpdate} />
            <Route path="/profile/name" exact={true} component={NameUpdate} />

            <Route path="/update/email/:token" component={VerifyUpdateEmail} />
            <Route path="/update/phone/:token" component={VerifyUpdatePhone} />
            <Route path="/clear-tests"  render={(obj) => {
              axios.get(config.API_CLEAR_TESTS)
            }} />

            <Route component={Page404} />
          </Switch>
          {this.state.showFooter && <Footer />}
        </div>
      </StripeProvider>
    )
  }
}

export default withRouter(Main);