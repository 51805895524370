import React from 'react'

class ShareMore extends React.Component
{
render()
{
    return (

        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="20"
        fill="none"
        viewBox="0 0 18 20"
      >
        <path
          fill="#0E0E0E"
          fillOpacity="0.75"
          d="M15 14.08c-.76 0-1.44.3-1.96.77L5.91 10.7c.05-.23.09-.46.09-.7 0-.24-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L5.04 7.81C4.5 7.31 3.79 7 3 7c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92 0-1.61-1.31-2.92-2.92-2.92z"
        ></path>
      </svg>
)
}
}

export default ShareMore;