import React from 'react'

import axios from 'axios'
import config from 'config';
import AppLink from 'Components/App/Link/Link'
import Loader from 'Components/Loader'

import {Auth} from 'Services/Auth'
import './index.scss'

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {data: {}, loading: true}
    }

    componentWillMount() {
        const {page} = this.props.match.params

        let component = this
        axios.get(config.API_PAGE_URL + '/' + page, {headers: {'X-Authorization': "bearer " + Auth.getToken()}})
            .then(function (response) {
                component.setState({
                    data: response.data,
                    loading: false,
                })

                if (document.querySelector(".open-contact"))
                    document.querySelector(".open-contact").addEventListener("click", function (e) {
                        e.preventDefault();
                        window.Beacon('toggle');
                    });
            });

    }

    render() {
        const {loading} = this.state
        return <div>

            <div className="page-container">
                <div className="breadcrumb">
                    <AppLink to="/settings" className="back"> </AppLink>
                    <h1>{this.state.data.title}</h1>
                </div>
                {loading && <Loader/>}

                <div className="page-content" dangerouslySetInnerHTML={{__html: this.state.data.body_html}}>

                </div>
            </div>
        </div>
    }
}

export default Page;