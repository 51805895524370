import React from 'react'

class BookmarkOn extends React.Component
{
render()
{
    return (

      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#0E0E0E"
        fillRule="evenodd"
        d="M4.5 4h15v17L12 14.674 4.5 21V4z"
        clipRule="evenodd"
      ></path>
    </svg>

)
}
}

export default BookmarkOn;