import React from 'react';
import axios from 'axios';
import config from 'config';
import {withRouter, Redirect} from 'react-router-dom'
import 'react-intl-tel-input/dist/main.css';
import {Auth} from 'Services/Auth'
import AppLink from 'Components/App/Link/Link'
import './../../index.scss'
import {store} from 'Redux/store';
import actions from 'Redux/actions';
import ButtonLoader from 'Components/Loader/ButtonLoader'

class NameUpdate extends React.Component {
    constructor(props) {

        const {auth} = store.getState()

        super(props);
        this.state = {
            responseReceived: false,
            user: {name: auth.user.name},
            errors: {},
            redirect: false,
            clicked: false,
            showSave: (auth.user.name && auth.user.name.length > 0)
        }

        this.handleChange = this.handleChange.bind(this)
        this.save = this.save.bind(this)
        this.redirectSomewhere = this.redirectSomewhere.bind(this)
    }


    redirectSomewhere() {
        this.setState({redirect: true});
    }

    handleChange(e) {
        this.setState({
            user: {
                ...this.state.user,
                [e.target.name]: e.target.value
            },
            showSave: (e.target.value.length > 0)
        })

    }

    save(e) {
        e.preventDefault();
        this.setState({clicked: true});

        const self = this

        axios.post(config.API_PROFILE_UPDATE_URL, {name: this.state.user.name}, {headers: {'X-Authorization': "bearer " + Auth.getToken()}})
            .then(function (response) {
                store.dispatch(actions.setUser(response.data))
                store.dispatch(actions.setNotification({ type: 'success', message: 'Name has been updated' }))
                self.setState({responseReceived: true});
                self.redirectSomewhere();
            }).catch(function (error) {
        });

    }

    render() {

        const {responseReceived, errors} = this.state
        const {auth} = store.getState()

        //console.log(!store.getState().auth.auth);

        if (this.state.redirect || !store.getState().auth.auth)
            return <Redirect to="/profile"/>

        return (
            <div>
                {responseReceived && <div className="alert-container">
                    <div id="alert" className="alert alert-success">NAME HAS BEEN UPDATED.</div>
                </div>}

                <form onSubmit={this.submit} className="profile-edit">
                    <div className="h1-container">
                        <h1 className="h1-header">
                            <AppLink to="/profile" className="cancel">Cancel</AppLink>Full Name

                            {this.state.clicked ?
                                <ButtonLoader/>
                                :
                                <button id="save-name" className="btn-primary save-name btn" onClick={this.save} style={this.state.showSave ? {display:"block"} : {display: "none"}}>
                                    Save
                                </button>
                            }
                        </h1>
                    </div>

                    <div className="forgotten">

                        <div className="form-group signup-error">
                            <input onChange={this.handleChange}
                                   className={"form-control " + (errors.name && 'is-invalid')} id="name" type="text"
                                   name="name" defaultValue={auth.user.name} placeholder="Your name here…"/>
                            <div className={errors.name ? 'invalid-feedback visible' : 'invalid-feedback'}>{errors.name}
                            </div>
                        </div>

                    </div>
                </form>

            </div>
        );
    }
}

export default withRouter(NameUpdate);