import { store } from '../Redux/store';
import actions from 'Redux/actions';

export const Auth = {
  login,
  logout,
  setToken,
  getToken,
};

  function login(username, password)
  {

  }

  function logout()
  {
    localStorage.removeItem('token');
    store.dispatch(actions.logout());
  }

  function setToken(token)
  {
      localStorage.setItem('token',token);
  }

  function getToken()
  {
      return localStorage.getItem('token');
  }

