import React from 'react'

class BookmarkBigOff extends React.Component
{
render()
{
    return (

        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="32"
        fill="none"
        viewBox="0 0 33 32"
      >
        <path fill="#fff" fillOpacity="0.01" d="M0 0h32v32H0V0z"></path>
        <path fill="#fff" fillOpacity="0.01" d="M1 0H33V32H1z"></path>
        <path
          fill="#0E0E0E"
          fillRule="evenodd"
          d="M5.85 28.326V4L6 3.85h21l.15.15v24.326L16.5 19.198 5.85 28.326zM7.15 5.15V25.5l9.252-7.931h.196l9.252 7.93V5.15H7.15z"
          clipRule="evenodd"
        ></path>
      </svg>

)
}
}

export default BookmarkBigOff;