import React from 'react';
import ProductList from 'Components/Product/ProductList';
import Join from 'Components/Overlay/Join';
import './App.scss'
import { store } from 'Redux/store';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import config from 'config';
import actions from 'Redux/actions';

import NewProducts from 'Components/Product/NewProducts';


export class App extends React.Component {
  constructor(props) {
    super(props)

    this.state =
      {
        redirect: false,
      }
    this.loadCategories = this.loadCategories.bind(this);
  }


  componentDidUpdate(prevProps) {
    const { settings } = store.getState()

    if (this.props.overlay && settings.overlay.content) {
      if (this.props.overlay !== settings.overlay.content) {
        store.dispatch(actions.showOverlay({ content: this.props.overlay }))
      }
    }



    this.loadCategories()
  }

  loadCategories(initial = false) {
    const { categories } = store.getState()

    if (categories.data && categories.data[0]) return;

    axios.get(config.API_COLLECTIONS_URL)
      .then(function (response) {
        store.dispatch(actions.saveCategories(response.data));
      });

  }

  componentWillMount() {

    if (this.props.overlay) {
      store.dispatch(actions.showOverlay({ content: this.props.overlay }))
    }

    this.unsubscribe = store.subscribe(() => {
      this.forceUpdate()
    });

  }

  componentWillUnmount() {
    this.unsubscribe();

  }

  render() {
    const { auth, settings } = store.getState();

    

    return (
      <div>
        {!auth.auth && ( settings.path === "home" || !settings.path)  && <Join />}
        {(settings.path  === "home"  || !settings.path) && <NewProducts auth={auth.auth} />}
        <ProductList type={this.props.match} />
      </div>
    );
  }
}

export default withRouter(App);
