import React from 'react'
import { store } from 'Redux/store'
import './Footer.scss'
import AppLink from 'Components/App/Link/Link'

import { withRouter } from 'react-router-dom'


import HomeOff from 'Components/Svg/TabBar/HomeOff'
import SearchOff from 'Components/Svg/TabBar/SearchOff'
import BookmarksOff from 'Components/Svg/TabBar/BookmarksOff'
import CategoriesOff from 'Components/Svg/TabBar/CategoriesOff'
import UserOff from 'Components/Svg/TabBar/UserOff'

import HomeOn from 'Components/Svg/TabBar/HomeOn'
import SearchOn from 'Components/Svg/TabBar/SearchOn'
import BookmarksOn from 'Components/Svg/TabBar/BookmarksOn'
import CategoriesOn from 'Components/Svg/TabBar/CategoriesOn'
import UserOn from 'Components/Svg/TabBar/UserOn'


import actions from 'Redux/actions'

class Footer extends React.Component {

    constructor(props) {
        super(props)
        this.toggleSearch = this.toggleSearch.bind(this)
    }


    toggleSearch() {
        const { search } = store.getState();

      
            if (search.open) {
                store.dispatch(actions.closeSearch())
            }
            else
                store.dispatch(actions.openSearch())
        
    }

    render() {
        const { search } = store.getState();
        const path = window.location.pathname

        return (

            <div className="footer">
                <ul>
                    <li><AppLink to="/">{(path === "/") ? <HomeOn /> : <HomeOff />}</AppLink></li>
                    <li><AppLink to="/categories">{(path === "/categories") ? <CategoriesOn /> : <CategoriesOff />}</AppLink></li>
                    <li><button onClick={this.toggleSearch}>{(path.startsWith('/search') || search.open) ? <SearchOn /> : <SearchOff />}</button></li>
                    <li><AppLink authonly="true" to="/bookmarked">{path === "/bookmarked" ? <BookmarksOn /> : <BookmarksOff />}</AppLink></li>
                    <li><AppLink to="/settings">{(path === "/settings" || path.startsWith('/profile')) ? <UserOn /> : <UserOff />}</AppLink></li>
                </ul>
                <div className="clearfix"></div>
                { /*<BottomBubble />*/}


            </div>



        );
    }
}

export default withRouter(Footer);