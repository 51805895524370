import React from 'react'
import './Product.scss'
import axios from 'axios'
import config from 'config';
import { store } from 'Redux/store';
import actions from 'Redux/actions';

import Checkout from 'Components/Checkout'
import Card from 'Components/Checkout/Card'

import './SingleProduct.scss'
import Bookmark from 'Components/Bookmark'
import Thankyou from 'Components/Thankyou'

import { Elements } from 'react-stripe-elements'

import { Auth } from 'Services/Auth'

import Product from './Product'

import Page404 from 'Components/App/Page404'
import ButtonLoader from 'Components/Loader/ButtonLoader'

import Slider from "react-slick";
import Loader from 'Components/Loader'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Helmet } from "react-helmet";

import Modal from './Modal'
import ShareModal from './ShareModal'



export class ImageSlider extends React.Component {



    getImagesWithOptions() {
        let images = this.props.images;
        const { variants, options } = this.props;
        for (let i = 0; i < variants.length; i++) {
            const imageIndex = images.findIndex(function (e) {
                return parseFloat(variants[i].image_id) === parseFloat(e.id)
            });
            if (imageIndex > -1) {
                images[imageIndex].optionString =
                    (variants[i].option1 ? options[0].name + ': ' + variants[i].option1 : '') +
                    (variants[i].option2 ? ', ' + options[1].name + ': ' + variants[i].option2 : '') +
                    (variants[i].option3 ? ', ' + options[2].name + ': ' + variants[i].option3 : '')
            }
        }
        return images
    }



    render() {

        const images = this.getImagesWithOptions()

        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        }

        return (
            <Slider onInit={this.setSliderHeight} ref={c => (this.slider = c)} {...settings}>
                {images.map((value, i) => (
                    <div key={i}>
                        {value.optionString && value.optionString !== 'Title: Default Title' && <div className='image-options'>{value.optionString}</div>}
                        <img width="100%" alt="" src={value.src} /></div>
                ))}
            </Slider>
        );
    }
}


class SingleProduct extends React.Component {
    constructor(props) {

        super(props)
        this.state = {
            data: {},
            description: '',
            fullDescription: '',
            currentDescription: '',
            showDescription: false,
            black: false,
            section: false,
            images: {},
            preSelected: '',
            loading: false,
            selectedVariant: {},
            related: [],
            buttonDisabled: false,
            payment_intent: {},
            deltaPosition: {
                x: 0, y: 0
            },
            scroll: 0
        }

        this.currentScroll = 0;
        this.loadProduct = this.loadProduct.bind(this)
        this.handleSection = this.handleSection.bind(this)
        this.toggleDescription = this.toggleDescription.bind(this)
        this.blackClick = this.blackClick.bind(this)
        this.openPaymentSection = this.openPaymentSection.bind(this)
        this.updatePreSelected = this.updatePreSelected.bind(this)
        this.handleOptions = this.handleOptions.bind(this)
        this.findVariant = this.findVariant.bind(this)
        this.updatePaymentIntent = this.updatePaymentIntent.bind(this)
        this.assignImagesToOptions = this.assignImagesToOptions.bind(this)
        this.handleCheckout = this.handleCheckout.bind(this)
        this.getVariant = this.getVariant.bind(this)

    }

    componentWillMount() {
        this.unsubscribe = store.subscribe(() => {
            this.forceUpdate()
        })

        this.loadProduct()
    }

    componentWillUnmount() {
        this.setState({ loading: true })
        this.unsubscribe();
    }

    componentDidUpdate(prevProps) {

        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({ data: {} });
            this.loadProduct();
        }

        if (window.location.href === store.getState().scroll.positions.url)
            window.scrollTo(0, store.getState().scroll.positions.position);

        const { payment } = store.getState();

        if (payment.thankyou === true && this.state.section !== 'thankyou') {

            this.setState({
                section: 'thankyou',
                black: true
            })

            document.body.classList.add('overlay-open')
        }

        if (payment.thankyou === false && this.state.section === 'thankyou') {
            this.setState({
                section: false,
                black: false
            })
        }

    }

    assignImagesToOptions() {
        let images = {}
        const variants = this.state.data.variants

        for (let i = 0; i < variants.length; i++) {

            if (!images[variants[i].option1] && images[variants[i].option1] !== -1)
                images[variants[i].option1] = this.state.data.images.find(function (e) {
                    return parseFloat(variants[i].image_id) === parseFloat(e.id)
                })

        }

        this.setState({ images: images })
    }

    //

    handleCheckout() {

        const id = this.state.selectedVariant.id
        const { auth } = store.getState()
        let qs = ''

        this.setState({ loading: true })
        const self = this

        if (auth.auth) {
            axios
                .get(config.API_GET_ADDRESSES_URL, {
                    headers: { "X-Authorization": "bearer " + Auth.getToken() }
                })
                .then(function (response) {

                    if (response.data.addresses && response.data.addresses.length) {
                        const address = response.data.addresses[0]
                        qs += '?email=' + auth.user.email
                        qs += '&checkout[shipping_address][first_name]=' + address.first_name
                        qs += '&checkout[shipping_address][last_name]=' + address.first_name
                        qs += '&checkout[shipping_address][phone]=' + address.phone
                        qs += '&checkout[shipping_address][city]=' + address.city
                        qs += '&checkout[shipping_address][address1]=' + address.address1
                        qs += '&checkout[shipping_address][address2]=' + address.address2
                        qs += '&checkout[shipping_address][province]=' + address.province_code
                        qs += '&checkout[shipping_address][country]=' + address.country_code
                        qs += '&checkout[shipping_address][zip]=' + address.zip
                    }
                    setTimeout(function () { document.location.href = config.CART_URL + id + ":1" + qs; }, 50);
                    self.setState({ loading: false })

                })
                .catch(function (error) { console.log(error);  self.setState({ loading: false }) })


        }
        else {
            setTimeout(function () { document.location.href = config.CART_URL + id + ":1"; }, 250);
        }



    }

    loadProduct() {
        let component = this
        axios.get(config.API_PRODUCT_URL + this.props.match.params.id, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {

                let selectedVariant;

                response.data.variants.every(function(variant, index) {
                    if (variant.inventory_quantity>1) {
                        selectedVariant = response.data.variants[index];
                        return false;
                    }
                    else return true;

                });

                component.setState({
                    data: response.data,
                    selectedVariant: selectedVariant,
                    option1: response.data.variants[0].option1,
                    option2: response.data.variants[0].option2,
                    option3: response.data.variants[0].option3,
                    description: response.data.body_html.substr(0, 150),
                    currentDescription: response.data.body_html.substr(0, 150),
                    fullDescription: response.data.body_html
                }, function () { this.assignImagesToOptions() })

                store.dispatch(actions.addVisited(response.data))
            })
            .catch(function (e) {
                if (e.response.status === 404)
                    component.setState({ notFound: true })
            });


        axios.get(config.API_RELATED_PRODUCTS_URL + this.props.match.params.id, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {
                component.setState({
                    related: response.data.slice(1).sort(() => Math.random() - 0.5)
                })
            })
            .catch(function (e) { })

    }

    toggleDescription() {
        this.setState({
            showDescription: !this.state.showDescription
        }, function () {

            if (this.state.showDescription === true)
                this.setState({
                    currentDescription: this.state.fullDescription
                })
            else
                this.setState({
                    currentDescription: this.state.description
                })

        })


    }

    handleSection(e) {
        const section = e.target.getAttribute("rel")
        this.setState({
            section: section,
            black: true,
            scroll: window.scrollY
        })

        document.body.classList.add('overlay-open')
    }

    openPaymentSection() {
        let component = this;
        this.setState({
            section: 'payment',
            black: true
        })

        axios.post(config.API_PAYMENT_INTENT_URL, {
            amount: this.state.selectedVariant.price * 100,
            line_items: [this.state.selectedVariant.id]

        }, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {
                component.setState({ payment_intent: response.data })

            });
    }

    updatePaymentIntent() {

        if (!this.state.payment_intent.id) return;

        let component = this

        this.setState({ buttonDisabled: true })

        axios.post(config.API_PAYMENT_INTENT_UPDATE_URL, {
            amount: this.state.selectedVariant.price * 100,
            payment_intent_id: this.state.payment_intent.id
        }, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {
                component.setState({ payment_intent: response.data, buttonDisabled: false })
            });
    }

    blackClick() {

        if (this.state.section === "thankyou")
            store.dispatch(actions.hideThankYou())

        this.setState({
            section: false,
            black: false
        })

        document.body.classList.remove('overlay-open')
        window.scrollTo(0, this.state.scroll);
    }

    updatePreSelected(e) {
        this.setState({
            preSelected: e.target.value,
        })
    }

    handleColor(data, val, unavailable) {
        //if (unavailable) return false;
        this.setState({
            [data]: val,
        }, function () { this.findVariant() })
    }

    handleOptions(e) {
        this.setState({
            [e.target.getAttribute('data-option')]: e.target.value,
        }, function () { this.findVariant() })
    }

    getVariant(option1, option2=false, option3=false) {
        const { data } = this.state

        var find = data.variants.filter(function (result) {
            return result.option1 === option1 && result.option2 === option2 && result.option3 === option3;
        });

        if (find[0].inventory_quantity<1)
            return true;
        else
            return false;  
    }

    findVariant() {
        const { data } = this.state
        const { selectedVariant } = this.state;
        const self = this

        var find = data.variants.filter(function (result) {
            return result.option1 === self.state.option1 && result.option2 === self.state.option2 && result.option3 === self.state.option3;
        });

        const current_price = selectedVariant.price

    //   if (find[0].inventory_quantity<1) return;

        this.setState({
            selectedVariant: find[0]
        }, function () {
            if (self.state.payment_intent && current_price !== find[0].price) { 
                this.updatePaymentIntent()
            }
        })        
    }

    render() {

        const { data, related, loading, images } = this.state
        const { auth } = store.getState()
        const { selectedVariant } = this.state

        return (
            <div>

                {data && data.id &&
                    <Helmet>
                        <title>WOW - {data.title}</title>
                        <meta name="twitter:title" content={data.title} />
                        <meta name="twitter:image" content={data.default_image.src} />
                        <meta property="og:title" content={data.title} />
                        <meta property="og:image" content={data.default_image.src} />
                    </Helmet>
                }
                <div onClick={this.blackClick} className={this.state.black ? "black visible" : "black"}></div>


                <div className="product-container">

                    {!data.id && !this.state.notFound &&
                        <Loader />
                    }

                    {this.state.notFound && <Page404 />}

                    {data && data.id && <div>
                        <div className="discount">-{Math.round(data.default_variant.discount)}%</div>
                        {data.images && data.images.length &&
                            <ImageSlider variants={data.variants} options={data.options} images={data.images} />
                        }

                        <div className="product-inner options">

                            <Bookmark icon="big" id_product={data.id} />

                            <ShareModal url={window.location.href} media={data.default_image.src} name={data.title} />

                            <h1>{data.title}</h1>

                            <div className="option shipping">
                                Free shipping included
                            </div>

                            <div className="clearfix"></div>
                        </div>


                        <div className="product-inner">

                            <div className="description">

                                <h2>Description</h2>

                                <div className="desc-full">
                                    {<div dangerouslySetInnerHTML={{ __html: data.body_html.replace(/<img[^>]*>/g, "").replace(/style="[^"]*"/g, "") }} className="padding"></div>}
                                </div>

                                <div className="desc-short">
                                    {<div dangerouslySetInnerHTML={{ __html: this.state.currentDescription + ((this.state.showDescription || this.state.fullDescription.length < 150? '' : '...')) }} className="padding"></div>}
                                </div>

                               {this.state.fullDescription.length>150 && <p onClick={this.toggleDescription} className="readmore">{this.state.showDescription ? 'Hide' : 'Read more'}</p>}

                            </div>

                        </div>

                        {(data.variants.length > 1) &&
                        <div className="product-inner variants">
                            <h2>Option {selectedVariant.inventory_quantity<1 && <span className="option-unavailable">Option not available</span>}</h2>
                            {selectedVariant && <div className="selected-option">
                                { selectedVariant.option1 }
                            </div>}
                        </div>}

                        {(data.variants.length > 1) && data.options.map((value, i) =>
                            (i === 0) ?

                                <div>
                                    {
                                        <div className="variantsContainer">
                                            <div className="variantsImages" style={{ width: 20 + 95 * value.values.length }}>
                                                {value.values.map((val, j) => (
                                                    
                                                    <div key={j} className={"variant variant" + j + ((this.state.selectedVariant["option"+(i + 1)] === val) ? " selected" : "") + ((this.getVariant(val, this.state.option2, this.state.option3)===true ? " unavailable" : "")) + " id"+data.variants[j].id} id={"variant" + j} onClick={() => this.handleColor("option" + (i + 1), val, data.variants[j].inventory_quantity<1? true:false)}>
                                                       
                                                        {images[val] ? <> <Modal src={images[val].src} />
                                                            <img alt="" src={images[val].src} /> </>
                                                            :
                                                            <div className="overlayThumbnail noPhoto">
                                                                <img src="/images/empty-product.png" alt="not-found-placeholder" />
                                                            </div>
                                                            }
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </div>

                                :

                                <div className="product-inner">
                                    <div key={i} className="option ">
                                        <h2>{value.name}</h2>
                                        <select data-option={"option" + (i + 1)} onChange={this.handleOptions} value={this.state.selectedVariant['option' + (i + 1)]}>
                                            {value.values.map((value, i) => (
                                                <option key={i}>{value}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                        )}

                        <div style={{ clear: "both" }}></div>

                        <div className="product-inner">
                            <div className="option payments">
                                <div className={this.state.section === "payment" ? "option-full full-description visible" : "option-full full-description"}>
                                    <button className="close-option" onClick={this.blackClick}>Close</button>

                                    <p className="center">Payment</p>
                                    <div className="hr"></div>
                                    <div>
                                        {selectedVariant.id && <Elements>
                                            <Card product={data} variant={selectedVariant} payment_intent={this.state.payment_intent} />
                                        </Elements>}
                                    </div>
                                </div>

                                <div className={this.state.section === "thankyou" ? "thankyou option-full full-description visible" : "thankyou option-full full-description"}>
                                    <button className="close-option" onClick={this.blackClick}>Close</button>
                                    <p className="center"><span>&nbsp;</span></p>


                                    <div className="desc">
                                        <Thankyou />
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="related-title">

                            <h2>You may also like</h2>
                        </div>

                        <div className="product-inner related">

                            <div className="products">
                                <div className="inproducts related">
                                    {related && related.length && related.map((value, index) => {
                                        return <Product className="col-6" key={index} data={value} />
                                    })}

                                    {!related && <Loader />}
                                </div>
                            </div>

                        </div>

                        {

                            <div className="pay-container">

                                <div className="pay">
                                    <div className="prices">
                                        {selectedVariant.price && <span className="price">${selectedVariant.price.replace(".00", "")}</span>}
                                        {selectedVariant.compare_at_price && <span className="stroke">${Math.round(selectedVariant.compare_at_price)} </span>}
                                    </div>
                                    {(auth.auth && 0) ?
                                        <Elements>
                                            <Checkout key={selectedVariant.price}
                                                product={data}
                                                selectedVariant={selectedVariant}
                                                onCardSelected={this.openPaymentSection}
                                                preSelected={this.state.preSelected} />
                                        </Elements>
                                        :

                                        <button onClick={this.handleCheckout} disabled={selectedVariant.inventory_quantity<1 || loading} className={loading ? "btn btn-primary buynow loading" : "btn btn-primary buynow"}> {
                                            loading
                                                ? <ButtonLoader />
                                                : <span>Buy now</span>
                                        }</button>



                                    }

                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        }
                    </div>}

                </div>

            </div>



        )
    }
}

export default SingleProduct;