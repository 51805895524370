import React from "react";
import { injectStripe, CardElement } from "react-stripe-elements";

import axios from "axios";
import { Auth } from "Services/Auth";
import { Order } from "Services/Order";
import config from "config";

import actions from "Redux/actions";
import { store } from "Redux/store";

import Loader from "Components/Loader";

import "./index.scss";
import SimpleReactValidator from "simple-react-validator";

class Card extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCardPayment = this.handleCardPayment.bind(this);
    this.handleReady = this.handleReady.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.stripeChange = this.stripeChange.bind(this);

    this.state = {
      product: this.props.product,
      shipping: {
        name: "",
        address1: "",
        city: "",
        country_code: "US",
        country: "US",
        province: "",
        zip: ""
      },
      billing: {},
      client_secret: {},
      errorStripe: "",
      error: {},
      payment_intent: {},
      stripe_foucus: false
    };

    this.validator = new SimpleReactValidator();
  }

  stripeChange(e){
    this.setState({stripe_focus: true});
  }

  handleChange(e) {

    this.setState({
      shipping: {
        ...this.state.shipping,
        [e.target.name]: e.target.value
      }
    });
  }

  componentWillMount() {
  
  }

  handleReady = element => {
    this.setState({ cardElement: element });
  };

  handleCardPayment(clientSecret) {
    const component = this;
    this.props.stripe
      .handleCardPayment(clientSecret, this.state.cardElement, {
        payment_method_data: {}
      })
      .then(function(result) {
        if (result.error) {
          component.setState({
            paymentSending: false,
            succeeded: false,
            errorStripe: result.error,
            details: {}
          });
        } else {
          if (result.paymentIntent.status === "succeeded") {


            axios
              .post(
                config.API_CREATE_ORDER_URL,
                Order.create(
                  component.props.variant,
                  component.state.shipping,
                  component.props.payment_intent.id
                ),
                { headers: { "X-Authorization": "bearer " + Auth.getToken() } }
              )
              .then(function(response) {
                if (response.data.order.id) {
                  component.setState({
                    paymentSending: false,
                    succeeded: true,
                    error: {},
                    details: result
                  });

                  store.dispatch(actions.saveLastOrder(response.data.order));
                  store.dispatch(actions.displayThankYou());
                }
              })
              .catch(function(error) {
               
                component.setState({
                  paymentSending: false
                });
              });
          } else if (result.paymentIntent.status === "requires_source") {
            component.handleCardPayment(this.state.client_secret);
          }
        }
      });
  }

  handleSubmit = ev => {
   
    ev.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ paymentSending: true });

      const component = this;
      component.handleCardPayment(this.props.payment_intent.client_secret);
    } else {
      this.setState({ error: this.validator.getErrorMessages() }, function() {
       
      });
      this.forceUpdate();
    }
  };
  onZipInput = e => {
    if (e.target.value.length > 5) {
      e.target.value = e.target.value.slice(0, 5);
    }
  };

  render() {
    const { error, errorStripe, shipping, paymentSending } = this.state;

    this.validator.message("name", shipping.name, "required|string|min:2");
    this.validator.message(
      "address1",
      shipping.address1,
      "required|string|min:5"
    );
    this.validator.message("city", shipping.city, "required|string|min:2");
    this.validator.message(
      "province",
      shipping.province,
      "required|string|min:2"
    );
    this.validator.message("zip", shipping.zip, "required|numeric|min:5|max:5");

    return (
      <form className="payform" onSubmit={this.handleSubmit}>
        <section>
          {/*  <h2>Shipping &amp; Billing Information</h2>*/}
          <fieldset className="with-state">
            <label className={error.name ? " invalid label" : "label"}>
              <span className="span"> </span>

              <div className="group">
                <input 
                type="text"  
                value={shipping.name}
                onChange={this.handleChange}
                name="name"
                className="field"
                required />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>Name</label>
              </div>

            </label>
            {/*} <label>
                <input defaultValue={auth.user.email} disabled={true} name="email" type="email" className="field" placeholder="Email" required />
                 </label>*/}
            
            <label className={error.address1 ? " invalid label" : "label"}>

            <div className="group">      
              <input
                  value={shipping.address1}
                  onChange={this.handleChange}
                  name="address1"
                  className="field"
                  required
                />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>Shipping Address</label>
              </div>

            </label>

            <label className={"label" + (error.city ? " invalid" : "")}>

            <div className="group">      
            <input
                value={shipping.city}
                onChange={this.handleChange}
                name="city"
                className="field"
                required
              />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>City</label>
              </div>

            </label>

            <div className="select-wrap select-state">
            <label className={"state " + (error.province ? " invalid" : "")}>
            <div className="select">

              {/*  <input value={shipping.province} onChange={this.handleChange} name="province" className="field" placeholder="State" />*/}

              <select
                value={shipping.province}
                onChange={this.handleChange}
                name="province"
                className="province select-text"
                required
              >
                <option value="" disabled></option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>

                <span className="select-highlight"></span>
                <span className="select-bar"></span>
                <label className="select-label">State</label>

              </div>
            </label>

            </div>


            <label className={"zip label" + (error.zip ? " invalid" : "")}>
    
            <div className="group">      
            <input inputMode="numeric" pattern="[0-9]*"
                onInput={event => this.onZipInput(event)}
                type="number"
                value={shipping.zip}
                onChange={this.handleChange}
                name="zip"
                className="field"
                required
              />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label>ZIP Code</label>
              </div>

            </label>

            <div className="select-wrap">
            <label className="label">
              {/* <div id="country" className="field US select">*/}
              <div className="select">
                <select
                  value={shipping.country_code}
                  onChange={this.handleChange}
                  name="country"
                  className="select-text"
                  required
                >
                  <option value="" disabled defaultValue></option>
                  {/*} <option value="AU">Australia</option>
                    <option value="AT">Austria</option>
                    <option value="BE">Belgium</option>
                    <option value="BR">Brazil</option>
                    <option value="CA">Canada</option>
                    <option value="CN">China</option>
                    <option value="DK">Denmark</option>
                    <option value="FI">Finland</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                    <option value="HK">Hong Kong</option>
                    <option value="IE">Ireland</option>
                    <option value="IT">Italy</option>
                    <option value="JP">Japan</option>
                    <option value="LU">Luxembourg</option>
                    <option value="MX">Mexico</option>
                    <option value="NL">Netherlands</option>
                    <option value="NZ">New Zealand</option>
                    <option value="NO">Norway</option>
                    <option value="PT">Portugal</option>
                    <option value="SG">Singapore</option>
                    <option value="ES">Spain</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="GB">United Kingdom</option>*/}
                  <option value="US">United States</option>
                </select>

                <span className="select-highlight"></span>
                <span className="select-bar"></span>
                <label className="select-label">Country</label>

              </div>
            </label>
          </div>

            <CardElement hidePostalCode={true} onReady={this.handleReady} onChange={this.stripeChange} style={
              {base: 
                {color: '#0E0E0E', fontSize: '15px',
                 '::placeholder': {
                  color: 'rgba(14, 14, 14, 0.3)', fontSize: '15px'}}}} />

            {paymentSending ? <Loader /> : <button disabled={!this.state.shipping.name || !this.state.shipping.address1 || !this.state.shipping.city || !this.state.shipping.province ||!this.state.shipping.zip || !this.state.stripe_focus }>Pay</button>}

            {errorStripe.message && (
              <div className="">{errorStripe.message}</div>
            )}
          </fieldset>
        </section>
      </form>
    );
  }
}

export default injectStripe(Card);
