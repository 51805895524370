import React from 'react';
import "bourbon";
import './Loader.scss'

class Loader extends React.Component
{
    render()
    {
        return (
            <div className="spinner"></div>
        )

    }
}

export default Loader;