// reducers.js
import types from 'Redux/types';

const INITIAL_STATE = {
  last : {},
}

const orderReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case types.LAST_ORDER_SAVE: {
      return {
        ...state,
        last: action.payload,
      }
    }

    case types.ORDERS_ADD: {
      return {
        ...state,
        data: action.data,
      }
    }

    default: return state;
  }
}


export default orderReducer;