import React from 'react'

import axios from 'axios'
import config from 'config';
import  AppLink  from 'Components/App/Link/Link'
import Loader from 'Components/Loader'

import { Auth } from 'Services/Auth'
import './index.scss'



class PageList extends React.Component {

    constructor(props) {
        super(props);

        this.state = { data: {}, loading: true }
    }

    componentWillMount() {
        const { tag } = this.props.match.params


        let component = this
        axios.get(config.API_PAGES_BY_TAG_URL + '/' + tag, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {
                component.setState({
                    data: response.data,
                    loading: false,
                })
            });
    }


    render() {

        const { data, loading } = this.state
        return <div>

            <div className="page-container">
                <div className="breadcrumb">
                    <h1>Customer Support</h1>
                </div>
                {loading && <Loader />}
                <ul className="arrow-links">
                    {data.articles && data.articles.map((value, index) => {
                        return <li><AppLink to={"/article/" + value.handle}>{value.title}</AppLink></li>
                    })}
                </ul>



            </div>
        
        </div>

    }
}

export default PageList;