import React from 'react'
import './Modal.scss'
import Share from '../Svg/Share'
import ShareFacebook from '../Svg/ShareFacebook'
import ShareTwitter from '../Svg/ShareTwitter'
import SharePinterest from '../Svg/SharePinterest'
import ShareWhatsapp from '../Svg/ShareWhatsapp'
import ShareMessage from '../Svg/ShareMessage'
import ShareEmail from '../Svg/ShareEmail'
import ShareCopy from '../Svg/ShareCopy'
import ShareMore from '../Svg/ShareMore'
import {
    MobileView
} from "react-device-detect";

import {CopyToClipboard} from 'react-copy-to-clipboard';

class ShareModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            scrollY: 0,
            copied: false,
            name: this.props.name,
            url: this.props.url
        }

        this.handleClick = this.handleClick.bind(this)
        this.onCopy = this.onCopy.bind(this)
        this.close = this.close.bind(this)
    }

    onCopy() {
        this.setState({copied: true});
    }

    handleClick() {
        this.setState({
            isOpen: true,
            scrollY: window.scrollY
        })

        document.body.classList.add('overlay-open')
    }

    close() {
        this.setState({
            isOpen: false
        })
        document.body.classList.remove('overlay-open')
        window.scrollTo(0, this.state.scrollY);
    }

    shareMore() {
        if (navigator.share) {
          navigator.share({
            title: "title",
            url: "url"
          }).then(() => {
            console.log('Thanks for sharing!');
          })
          .catch(console.error);
        } else {
          // fallback
        }
    }

    render() {

        if (this.state.isOpen) {  
            document.getElementById('body').style.top = "-" + this.state.scrollY + "px";

            return (<>
            <button className="share" onClick={this.handleClick}>
                <Share />
            </button>
            <div className="overlay share" onClick={this.handleOverlayClick} >
                <div className="xycenter" onClick={(e) => { e.stopPropagation() }}>
                    <button className="close" onClick={this.close}>Close</button>
                  
                    <ul>
                        <li><a target="_blank" rel="noopener noreferrer" href={"https://www.facebook.com/sharer/sharer.php?u=" + this.props.url}><ShareFacebook />Share</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" className="twitter" href={"https://twitter.com/share?url="+this.props.url+"&amp;text="+this.props.name}><ShareTwitter />Tweet</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href={"http://pinterest.com/pin/create/button/?url="+this.props.url+"&media="+this.props.media+"&description="+this.props.name}><SharePinterest />Pin</a></li>
                        
                        <MobileView>
                        <li><a target="_blank" rel="noopener noreferrer" href={"whatsapp://send?text="+this.props.name+" "+this.props.url}><ShareWhatsapp />Message (WhatsApp)</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href={"sms:&body="+this.props.name+" "+this.props.url}><ShareMessage />Message</a></li>
                        </MobileView>

                        <li><a target="_blank" rel="noopener noreferrer" href={"mailto:?subject=Thought you might like this - WOW&body="+this.props.name+" "+this.props.url}><ShareEmail />Email</a></li>

                        <li>
                            <CopyToClipboard text={window.location.href}>
                                <button className="copy" onClick={this.onCopy}><ShareCopy />{this.state.copied ?  'Link copied!' : 'Copy link'}</button>
                            </CopyToClipboard> 
                        </li>
                        <MobileView>
                            <li><button className="shareMore" onClick={this.shareMore}><ShareMore />More</button></li>
                        </MobileView>

                    </ul>
                  
                </div>
            </div>
            </>);
        }
        else
            return (
            <button className="share" onClick={this.handleClick}>
                <Share />
            </button>
            
            );
      }
}

export default ShareModal;