import React from 'react'
import './index.scss'

import { withRouter, Link } from 'react-router-dom'

import { store } from 'Redux/store'
import Product from 'Components/Product/Product'

class Page404 extends React.Component {


    render() {

        const { products } = store.getState()

        return (
            
            <div className="page-404">
               <h1>Page not found</h1>
               <p className="not-found">
                   Uh oh, this is awkward. It looks like we can't<br /> find the page you're looking for.
                   
               </p>
               <p className="continue">
               <Link to="/">Continue Shopping</Link>
               </p>
            
            <div className="products page404-products">
            { products.popular && products.popular.products.map((value, index) => {
                return <Product template="simple" className="col-4" key={index} data={value} />
            }) }
            </div>
            </div>
            
        );
    }
}

export default withRouter(Page404);