import Magnificer from 'Components/Svg/Magnificer'
import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import AppLink from 'Components/App/Link/Link'

import actions from 'Redux/actions'
import { store } from 'Redux/store'
import axios from 'axios'
import config from 'config'
import { Auth } from 'Services/Auth'

import Product from 'Components/Product/Product';

import './index.scss'

class Search extends React.Component {

  constructor(props) {
    super(props)

    this.toggleSearch = this.toggleSearch.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handlePhrase = this.handlePhrase.bind(this)
    this.fetchHints = this.fetchHints.bind(this)
    this.autoSuggest = this.autoSuggest.bind(this)

    this.state =
      {
        phrase: '',
        typing: false,
        typingTimeout: 0,
        redirect: false,
        auto_suggest: [],
        trends: [],
        recent: [],
      }
  }

  componentDidUpdate() {


    if (this.state.redirect)
      this.setState({ redirect: false });
  }

  componentWillMount() {
    this.unsubscribe = store.subscribe(() => {
      this.forceUpdate()
    });
    this.fetchHints()

  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  toggleSearch() {
    const { search } = store.getState();

      if (search.open) {

        store.dispatch(actions.closeSearch())
      }
      else {
        this.setState({ auto_suggest: [] }, function () { store.dispatch(actions.openSearch()) })

      }
    

  }

  handleSearch(e) {
    e.preventDefault()

    const phrase = this.state.phrase

    if (phrase.length <= 1)
      return

    this.setState({ redirect: true })
    store.dispatch(actions.closeSearch())
  }

  handlePhrase(e) {
    this.setState({ phrase: e.target.value })
  }

  fetchHints() {
    axios.get(config.API_SEARCH_HINTS_URL, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
      .then(function (response) {
        store.dispatch(actions.saveSearchHints(response.data))
      });
  }

  changePhrase = (event) => {
    const self = this;
    const phrase = event.target.value
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout)
    }


    self.setState({
      phrase: phrase,
      typing: false,
      auto_suggest: this.filterAutoSuggest(phrase, this.state.auto_suggest),
      typingTimeout: setTimeout(function () {
        self.autoSuggest(phrase)
      }, 300)
    });
  }

  filterAutoSuggest(phrase, array) {
    var results = [];
    for (var i = 0; i < array.length; i++) {
      if (array[i].phrase.toLowerCase().indexOf(phrase.toLowerCase()) === 0) {
        results.push(array[i]);
      }
    }
    return results;
  }

  autoSuggest(phrase) {
    const self = this
    axios.get(config.API_SEARCH_AUTO_SUGGEST + phrase, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
      .then(function (response) {
        self.setState({
          auto_suggest: response.data
        })
      });
  }

  render() {
    const { search, categories } = store.getState()
    const { trends, recent, visited } = search
    const { auto_suggest } = this.state

    var searchStyle = {
      height: window.innerHeight + 'px'
    }

    return (
      <div>

        {this.state.redirect && <Redirect to={"/search/" + this.state.phrase} />}

        {!search.open && <div onClick={this.toggleSearch} className="search-button"><Magnificer /></div>}

        {
          search.open &&
          <form className="search-bar" onSubmit={this.handleSearch}>
            <input onChange={this.changePhrase} type="text" placeholder="What are you looking for?" />
            <button type="button" onClick={this.toggleSearch}>Cancel</button>
          </form>}
        {search.open &&
          <div className="search-container">

            <div className="search-box" style={searchStyle}>
              {auto_suggest && auto_suggest.length > 0 && <div>
                <ul className="auto-suggest">
                  {auto_suggest.slice(0, 5).map((value, i) => <li key={i}><AppLink to={"/search/" + value.phrase}><strong>{this.state.phrase}</strong><span>{value.phrase.toLowerCase().replace(this.state.phrase.toLowerCase(), '')}</span></AppLink></li>)}
                </ul></div>
              }

              {recent && recent.length > 0 && <div><div className="title"><span>Recent searches</span> <a href="/">View more</a></div>
                <ul className="recent">
                  {recent && recent.length > 0 && recent.slice(0, 5).map((value, i) => <li key={i}><AppLink to={"/search/" + value}>{value}</AppLink></li>)}
                </ul></div>
              }

              {false && trends && trends.length > 0 && <div> <div className="title"><span>Trending searches</span> <a href="/">View more</a></div>
                <ul className="trending">
                  {trends && trends.length > 0 && trends.slice(0, 5).map((value, i) => <li key={i}><AppLink to={"/search/" + value}>{value}</AppLink></li>)}
                </ul></div>
              }

              {visited.length > 0 && <div>
                <div className="title"><span>Recently viewed</span> <a href="/">View more</a></div>
                <div className="recently-container">
                  <ul className="recently" style={{ width: 20 + visited.slice(0, 30).length * 110 + 'px' }}>
                    {visited && visited.length > 0 && visited.slice(0, 30).map((value, i) =>
                      <Product className="col-6" key={i} data={value} />
                    )}
                  </ul>
                </div>

              </div>
              }
              <div className="title"><span>Categories</span></div>
              <ul className="categories row">
                {categories.data && categories.data.length && categories.data.map((value, i) =>
                  <li className="category" key={i}>
                    <AppLink to={"/category/" + value.handle}>
                      {value.image && <div><img alt="category" src={value.image} /></div>}
                      <span>{value.title}</span>
                    </AppLink>
                  </li>
                )}
              </ul>
            </div>

          </div>

        }
      </div>
    )
  }
}



export default withRouter(Search);