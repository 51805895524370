import types from 'Redux/types';

const INITIAL_STATE = {
 feed: false,
 overlay: {},
}

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {

    case types.FEED_TYPE_SAVE: {
      return {
        ...state,
        feedType: action.payload
      }
    }

    case types.NOTIFICATION_SET: {
      return {
        ...state,
        notification: action.payload
      }
    }

    case types.SUBSCRIBED_SET: {
      return {
        ...state,
        subscribed: true
      }
    }

    case types.NOTIFICATION_REMOVE: {
      return {
        ...state,
        notification: {}
      }
    }

    case types.OVERLAY_SHOW: {
      document.body.classList.add('overlay-open');
      return {
        ...state,
        overlay: action.payload
      }
    }

    case types.OVERLAY_HIDE: {
      document.body.classList.remove('overlay-open');
      return {
        ...state,
        overlay: {},
      }
    }

    case types.PATH_UPDATE: {
      return {
        ...state,
        path: action.short,
        url: action.url,
      }
    }
 
    default: return state;
  }
}

export default settingsReducer;