import React from 'react'
import { Redirect } from 'react-router-dom'
import AppLink from 'Components/App/Link/Link'
import axios from 'axios'
import config from 'config';
import { store } from 'Redux/store';
import actions from 'Redux/actions'

import { Auth } from 'Services/Auth'
import './../index.scss'

import Gravatar from 'react-gravatar'

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

class Profile extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            user: { },
        }

    }

    componentDidMount() {

        this.unsubscribe = store.subscribe(() => {
            this.forceUpdate()
        })

        axios.post(config.API_ME,{}, { headers: { 'Cache-Control': 'no-cache' , 'X-Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {
                console.log(response.data)
                store.dispatch(actions.setUser(response.data))
            }).catch(function (error) { console.log(error) });
    }

    componentWillUnmount() {
        this.unsubscribe()
    }



    render() {

        const { auth } = store.getState()

        if (!auth.auth)
            return <Redirect to="/login" />

        return <div >
            <div className="settings-container">
                <div className="row settings-header">
                    <div className="col-12"><h1>Edit Profile</h1></div>
                </div>

                <div>


                    <div className="row profile-container">
                        <div className="photo">
                            <Gravatar email={auth.user.email} size={100} rating="pg" default="monsterid" className="CustomAvatar-image" />
                        </div>

                        <form>
                            <ul className="profile-ul">
                                <li>
                                    <AppLink to="/profile/name">
                                        <h2>Full Name</h2>
                                        <div className="value">{auth.user.name}</div>
                                    </AppLink>
                                    <div className="clearfix"></div>
                                </li>
                                <li>
                                    <AppLink to="/profile/phone" className="phoneLink">
                                        <h2>Phone</h2>
                                        <div className="value">
                                        {auth.user.phone_number && 
                                           
                                               <> <IntlTelInput
                                                    containerClassName="intl-tel-input form-group signup"
                                                    inputClassName={"form-control "}
                                                    fieldName="phone_number"
                                                    fieldId="phone"
                                                    formatOnInit="false"
                                                    defaultValue={auth.user.phone_number}
                                                    format="true"
                                                    telInputProps={{ readOnly: 'readonly' }}
                                                    separateDialCode="true"
                                                    nationalMode="false"
                                                />
                                                <div className="mask"></div>
                                               </>
                                                }
                                               

                                        </div>
                                    </AppLink>
                                    <div className="clearfix"></div>


                                </li>
                                <li>
                                    <AppLink to="/profile/email">
                                        <h2>Email</h2>
                                        <div className="value">{auth.user.email}</div>
                                    </AppLink>
                                    <div className="clearfix"></div>
                                </li>
                                {/*    <li>
                                    <AppLink to="/profile/password" className="password">
                                      <h2>Password</h2>
                                      <div className="value password">••••••••</div>
                                    </AppLink>
                                      <div className="clearfix"></div>
                                    </li>*/}
                            </ul>
                        </form>

                    </div>


                </div>

            </div>


        </div>

    }
}

export default Profile;