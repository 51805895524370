import React from 'react'

class UserOff extends React.Component
{
render()
{
    return (
<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">

    <g id="Icons-&amp;-Guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-436.000000, -180.000000)">
            <g id="icon/tab-bar/user-off" transform="translate(433.000000, 177.000000)">
                <rect id="bounding-shape" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="32" height="32"></rect>
                <path d="M16,16.15 C12.6034488,16.15 9.85,13.3965512 9.85,10 C9.85,6.60344879 12.6034488,3.85 16,3.85 C19.3965512,3.85 22.15,6.60344879 22.15,10 C22.15,13.3965512 19.3965512,16.15 16,16.15 Z M16,14.85 C18.678581,14.85 20.85,12.678581 20.85,10 C20.85,7.32141896 18.678581,5.15 16,5.15 C13.321419,5.15 11.15,7.32141896 11.15,10 C11.15,12.678581 13.321419,14.85 16,14.85 Z M28.15,28 L28,28.15 L27,28.15 L26.85,28 L26.85,25 C26.85,22.321419 24.678581,20.15 22,20.15 L10,20.15 C7.32141896,20.15 5.15,22.321419 5.15,25 L5.15,28 L5,28.15 L4,28.15 L3.85,28 L3.85,25 C3.85,21.6034488 6.60344879,18.85 10,18.85 L22,18.85 C25.3965512,18.85 28.15,21.6034488 28.15,25 L28.15,28 Z" id="Combined-Shape" fill="#0E0E0E"></path>
            </g>
        </g>
    </g>
</svg>

)
}
}

export default UserOff;