import types from 'Redux/types.js';

const addProducts = (data) => ({
    type: types.PRODUCTS_ADD,
    products: data.products,
    count: data.count,
    page: data.page,
    lastpage: data.lastpage,
  })

const addOrders = (data) => ({
    type: types.ORDERS_ADD,
    data: data.orders,
  }) 

const addPopularProducts = (data) => ({
    type: types.PRODUCTS_POPULAR_ADD,
    data: data,
  })

const addInitialProducts = (data) => ({
    type: types.PRODUCTS_INITIAL_ADD,
    products: data.products,
    count: data.count,
    page: data.page,
    lastpage: data.lastpage,
  });

const saveCategories = (data) => ({
    type: types.CATEGORIES_SAVE,
    data: data,
  })

const loadingProducts = () => ({
    type: types.PRODUCTS_LOADING
  }) 

const setBookmarks = (bookmarks) => ({
    type: types.BOOKMARKS_SET,
    payload: bookmarks
  })

const loadedProducts = () => ({
    type: types.PRODUCTS_LOADED
  })

const selectVariant = (data) => ({
    type: types.SELECT_VARIANT,
    selectedVariant: data
  });

const authenticate = (token) => ({
    type: types.AUTHENTICATE,
    payload: token,
  })

const savePhoneNumber = (number) => ({
    type: types.PHONE_NUMBER_SAVE,
    payload: number,
  })  
  
const setUser = (user) => ({
    type: types.USER_SET,
    payload: user,
  })

const logout = () => ({
    type: types.LOGOUT
  })

const saveSearchHints = (hints) => ({
    type: types.SEARCH_HINTS_SAVE,
    payload: hints,
  })

const addVisited = (product) => ({
    type: types.VISITED_ADD,
    payload: product,
  })


const updatePaymentMethods = (methods) => ({
    type: types.PAYMENT_METHODS_UPDATE,
    payload: methods,
  })

const saveLastOrder = (order) => ({
    type: types.LAST_ORDER_SAVE,
    payload: order,
  })  

  const saveScrollPosition = (position) => ({
    type: types.SAVE_SCROLL_POSITION,
    payload: position,
  })

  const saveScroll = (data) => ({
    type: types.SAVE_SCROLL,
    payload: data,
  })  

  const saveFeedType = (type) => ({
    type: types.FEED_TYPE_SAVE,
    payload: type,
  })  

  const setNotification = (notification) => ({
    type: types.NOTIFICATION_SET,
    payload: notification,
  })

  const setSubscribed = () => ({
    type: types.SUBSCRIBED_SET,
  })

  const removeNotification = () => ({
    type: types.NOTIFICATION_REMOVE,
  })  

  const openSearch = () => ({
    type: types.SEARCH_OPEN,
  })  

  const closeSearch = () => ({
    type: types.SEARCH_CLOSE,
  })   
 
  const cleanProducts = () => ({
    type: types.PRODUCTS_CLEAN,
  }) 

  const displayThankYou = () => ({
    type: types.DISPLAY_THANK_YOU,
  }) 

  const hideThankYou = () => ({
    type: types.HIDE_THANK_YOU,
  }) 

  const showOverlay = (payload) => ({
    type: types.OVERLAY_SHOW,
    payload: payload,
  }) 

  const hideOverlay = () => ({
    type: types.OVERLAY_HIDE,
  })

  const updatePath = () => ({
    type: types.PATH_UPDATE,
    short: (window.location.pathname === "/") ? 'home' : window.location.pathname.split("/")[1],
    url: window.location.pathname
  })


  export default {
      addProducts,
      addInitialProducts,
      loadingProducts,
      loadedProducts,
      authenticate,
      logout,
      setUser,
      setBookmarks,
      saveCategories,
      saveSearchHints,
      updatePaymentMethods,
      addPopularProducts,
      saveLastOrder,
      addOrders,
      savePhoneNumber,
      openSearch,
      closeSearch,
      cleanProducts,
      displayThankYou,
      hideThankYou,
      saveScrollPosition,
      saveScroll,
      saveFeedType,
      addVisited,
      setNotification,
      removeNotification,
      showOverlay,
      hideOverlay,
      updatePath,
      setSubscribed,
      selectVariant
  }
