import React from 'react'

class Share extends React.Component
{
render()
{
    return (

      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        stroke="#0E0E0E"
        strokeMiterlimit="10"
        strokeWidth="1.2"
        d="M5 20v7a1 1 0 001 1h20a1 1 0 001-1v-7M16 22.667V5m0 0l5.667 5.667M16 5l-5.667 5.667"
      ></path>
    </svg>

)
}
}

export default Share;