
import React from 'react'

class BookmarksOn extends React.Component
{
render()
{
    return (
  <svg width="23px" height="26px" viewBox="0 0 23 26" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <g id="Icons-&amp;-Guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-949.000000, -52.000000)">
            <g id="icon/tab-bar/bookmark-on" transform="translate(945.000000, 49.000000)">
                <rect id="bounding-shape" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="32" height="32"></rect>
                <path d="M5,4 L26,4 C26,4.8954305 26,5.8954305 26,7 L26,28 L15.5,19 L5,28 L5,7 C5,5.8954305 5,4.8954305 5,4 Z" id="icon/tab-bar/bookmark-off" stroke="#0E0E0E" strokeWidth="0.3" fill="#0E0E0E"></path>
            </g>
        </g>
    </g>
</svg>

)
}
}

export default BookmarksOn;