import React from 'react';
import axios from 'axios';
import config from 'config';
import { withRouter } from 'react-router-dom'
import  AppLink  from 'Components/App/Link/Link'
import SimpleReactValidator from 'simple-react-validator'
import 'react-intl-tel-input/dist/main.css';
import { store } from '../../../Redux/store';
import actions from 'Redux/actions'
import ButtonLoader from 'Components/Loader/ButtonLoader'

class Reset extends React.Component {
  constructor(props) {
    super(props)

    console.log(props)
    
    this.state = {
      password: '',
      responseReceived: false,
      errors: {},
      button_active: false,
      loading: false
    }

    this.updatePassword = this.updatePassword.bind(this);
    this.update = this.update.bind(this);
    this.submit = this.submit.bind(this);

    this.validator = new SimpleReactValidator();
  }

  componentWillMount()
  {
   // store.dispatch(actions.logout())
  }

  updatePassword() {
    const component = this
    const params = this.props.location.pathname.split("/")
   
    axios.post(config.API_RESET_URL, {
      password: this.state.password,
      token: params[2],
    })
      .then(function (response) {
        component.setState({responseReceived: true})
        if(response.data.user)
        {
          store.dispatch(actions.authenticate(response.data.access_token))
          store.dispatch(actions.setUser(response.data.user))
          component.setState({ loading: false });
        }
      })
      .catch(function (error) {
        console.log(error)
        component.setState({error: error.response.data.error, loading: false})
       
      });
  }

  update(e)
  {
     const { name, value } = e.target;

      this.setState({
        ...this.state,
        [name]: value,
      });

      if (value.length>5)
      this.setState({
        button_active: true
      });
    else
      this.setState({
        button_active: false
      });
  }

  submit(e) {
    e.preventDefault();

    this.setState({ loading: true });

    if (this.validator.allValid()) {
      this.updatePassword();
    } else {
      this.setState({ errors: this.validator.getErrorMessages(), loading: false });
      this.forceUpdate();
    }
  }

  render() {
    const { responseReceived, errors } = this.state
    this.validator.message('password', this.state.password, 'required|min:6')

    return (
      <div>
        {responseReceived === false &&
          <form onSubmit={this.submit} className="new-password">
            <p className="prompt">Enter your new password.</p>

            <div className="form-group signup">
              <input className={"form-control " + (errors.password && 'is-invalid')} id="password" name="password" type="password" onFocus={this.onFocus} placeholder="Password" onChange={this.update} />
              <div className="invalid-feedback">{errors.password}</div>
            </div>


            { this.state.button_active ?
            <button className={this.state.loading ? "btn-primary btn loading" : "btn-primary btn"}>
              {this.state.loading ? <ButtonLoader /> : "Continue" }</button> :
            <button className="btn-primary btn" disabled="disabled">Continue</button>
            }

          </form>}
        {
          responseReceived && <span className="prompt center">
            
            Your password updated successfully.<br /><AppLink onClick={() => store.dispatch(actions.setNotification({ type: 'success', message: 'Password has been updated' }))} to="/" >Go to the homepage</AppLink></span>
        }
      </div>
    );
  }
}

export default withRouter(Reset);