import React from 'react'

class ShareFacebook extends React.Component
{
render()
{
    return (

        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          fill="#0E0E0E"
          fillOpacity="0.75"
          fillRule="evenodd"
          d="M9.614 18H.994A.993.993 0 010 17.006V.993C0 .445.445 0 .993 0h16.014c.548 0 .993.445.993.993v16.014a.993.993 0 01-.993.993H12.42v-6.97h2.34l.35-2.717h-2.69V6.578c0-.786.218-1.322 1.346-1.322h1.438v-2.43a19.23 19.23 0 00-2.096-.107c-2.074 0-3.494 1.266-3.494 3.59v2.004H7.27v2.716h2.345V18z"
          clipRule="evenodd"
        ></path>
      </svg>

)
}
}

export default ShareFacebook;