import React from 'react'

class ShareMessage extends React.Component
{
render()
{
    return (

        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          fill="#0E0E0E"
          fillOpacity="0.75"
          d="M9 0C4.037 0 0 3.701 0 8.25c0 2.252.996 4.29 2.598 5.781.182.169.29.411.247.655-.148.86-.552 1.751-1.224 2.652l-.049.066a.377.377 0 00.303.596c1.9 0 3.188-.711 4.067-1.604.188-.19.467-.245.727-.181A9.67 9.67 0 009 16.5c4.963 0 9-3.701 9-8.25C18 3.701 13.963 0 9 0z"
        ></path>
      </svg>
    );


}
}

export default ShareMessage;