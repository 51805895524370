import React from 'react'

import axios from 'axios'
import config from 'config';
import  AppLink  from 'Components/App/Link/Link'

import { Auth } from 'Services/Auth'
import './index.scss'
import Loader from 'Components/Loader'

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = { data: {}, loading: true, }
    }

    componentWillMount() {
        const { article } = this.props.match.params
        
        let component = this
        axios.get(config.API_ARTICLE_URL + '/' + article, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {
                component.setState({ 
                    data: response.data,
                    loading:false,
                 })
            });
    }

    render() {
        const { loading } = this.state
        return <div>

                <div className="page-container">
                { loading && <Loader /> }
                { !loading && <div>
                    <div className="breadcrumb">
                    <AppLink to="/articles/support" className="back"> </AppLink>
                    <h1>{this.state.data.title}</h1>
                    </div>
                    
                    <div className="page-content" dangerouslySetInnerHTML={{ __html: this.state.data.body_html }} >

                    </div>
                    </div>
                    }
                </div>    
        </div>
    }
}

export default Page;