import React from 'react'
import { store } from 'Redux/store'
import actions from 'Redux/actions';

import BookmarkOff from '../Svg/BookmarkOff'
import BookmarkOn from '../Svg/BookmarkOn'
import BookmarkBigOff from '../Svg/BookmarkBigOff'
import BookmarkBigOn from '../Svg/BookmarkBigOn'
import axios from 'axios'

import { Auth } from 'Services/Auth'
import './Bookmark.scss'

import config from 'config';

class Bookmark extends React.Component {
    constructor(props) {
        super(props);

        this.addBookmark = this.addBookmark.bind(this);
        this.removeBookmark = this.removeBookmark.bind(this);
        this.getBookmarks = this.getBookmarks.bind(this);
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);

        this.state = {
            loading: false,
        }
    }

    handleMouseOut(e) {
        if (e.target.closest(".product"))
            e.target.closest(".product").classList.remove('disabled')
    }
    handleMouseOver(e) {

        if (e.target.closest(".product"))
            e.target.closest(".product").classList.add('disabled')
    }

    addBookmark() {

        const { auth } = store.getState()
        if (!auth.auth) { 
            store.dispatch(actions.showOverlay({ content: "login", from: "bookmark", scroll: window.scrollY }))
            return
        }

        const self = this
        this.setState({ loading: true })
        axios.post(config.API_BOOKMARK_URL, { id_product: this.props.id_product }, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {

                self.setState({ loading: false });
                store.dispatch(actions.setBookmarks(response.data));
            });
    }

    removeBookmark() {
        const { auth } = store.getState()
        if (!auth.auth) {
            store.dispatch(actions.showOverlay({ content: "login", from: "bookmark" }))
            return
        }
        const self = this
        this.setState({ loading: true })
        axios.delete(config.API_BOOKMARK_URL, { headers: { 'X-Authorization': "bearer " + Auth.getToken() }, data: { id_product: this.props.id_product } })
            .then(function (response) {
                self.setState({ loading: false });
                store.dispatch(actions.setBookmarks(response.data));
            });
    }

    getBookmarks() {
        axios.get(config.API_BOOKMARK_URL, {}, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
            .then(function (response) {
                console(response)
                store.dispatch(actions.setBookmarks(response.data));
            })
            .catch(function (error) { });
    }

    isBookmarked() {
        let { bookmarks } = store.getState()
        bookmarks = bookmarks.data
        return (bookmarks.indexOf(this.props.id_product.toString()) > -1)
    }

    render() {
        const bookmarked = this.isBookmarked()
        const { auth } = store.getState()
        const icon = this.props.icon

        if (icon==="big") {
            return (
                <div className="bookmark" onTouchStart={this.handleMouseOver} onTouchEnd={this.handleMouseOut}>
                    {((bookmarked && !this.state.loading) || (auth.auth && (!bookmarked && this.state.loading))) ? <div onClick={this.removeBookmark}><BookmarkBigOn /></div> : <div onClick={this.addBookmark}><BookmarkBigOff /></div>}
                </div>

            );
        }

        else 
            return (
                <div className="bookmark" onTouchStart={this.handleMouseOver} onTouchEnd={this.handleMouseOut}>
                    {((bookmarked && !this.state.loading) || (auth.auth && (!bookmarked && this.state.loading))) ? <div onClick={this.removeBookmark}><BookmarkOn /></div> : <div onClick={this.addBookmark}><BookmarkOff /></div>}
                </div>

            );
    }
}

export default Bookmark;