import React from 'react'

class Magnificer extends React.Component
{
render()
{
    return (

<svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <g id="Home" fill="none" fillRule="evenodd">
        <g id="Home---default" transform="translate(-338 -13)" fill="#FFF">
            <g id="Molecule-/-Header-/-Default">
                <g id="Header">
                    <g id="icn_search_header" transform="translate(333 8)">
                        <rect id="bounding-shape" fillOpacity="0" width="32" height="32" />
                        <path d="M14,22.15 C9.49887929,22.15 5.85,18.5011207 5.85,14 C5.85,9.49887929 9.49887929,5.85 14,5.85 C18.5011207,5.85 22.15,9.49887929 22.15,14 C22.15,15.9653163 21.4516133,17.8225852 20.2040149,19.2853888 L26.106066,25.1874399 L26.106066,25.399572 L25.399572,26.106066 L25.1874399,26.106066 L19.2853888,20.2040149 C17.8225852,21.4516133 15.9653163,22.15 14,22.15 Z M13.9991334,20.8491334 C17.782284,20.8491334 20.8491334,17.782284 20.8491334,13.9991334 C20.8491334,10.2159829 17.782284,7.14913345 13.9991334,7.14913345 C10.2159829,7.14913345 7.14913345,10.2159829 7.14913345,13.9991334 C7.14913345,17.782284 10.2159829,20.8491334 13.9991334,20.8491334 Z"
                        id="Color" />
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

)
}
}

export default Magnificer;