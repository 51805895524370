import React from 'react'
import AppLink from 'Components/App/Link/Link'
import './index.scss'
import { store } from 'Redux/store'

class Settings extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    render() {
        const { auth } = store.getState()
        return <div>

            <div className="settings-container">
                <h1>Settings</h1>

                <ul className="settings-ul">

                    <li><AppLink authonly="true" to="/profile" className="profile">Profile</AppLink></li>
                    {/* <li><AppLink to="/orders" className="history">Order History</AppLink></li> */}
                    <li><AppLink to="/page/privacy" className="privacy">Privacy Policy</AppLink></li>
                    <li><AppLink to="/page/terms-and-conditions" className="terms">Terms &amp; Conditions</AppLink></li>
                </ul>

                {auth.auth && <AppLink to="/logout" className="logout">Log out</AppLink>}
            </div>

        </div>

    }
}

export default Settings;