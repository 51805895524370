import React from 'react';

import './index.scss'

class Join extends React.Component {

  render() {

    return (
      <div className="join">

        <h1>Shopping made easy<span className="dot">.</span></h1>
        <p>Thousands of curated products.<br />Amazing discounts.<br />Right at your fingertips.</p>
      </div>
    );
  }
}

export default Join;