// reducers.js
import types from 'Redux/types';

const INITIAL_STATE = {
  positions: {}
}

const scrollReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {

    case types.SAVE_SCROLL : {
      return {
        ...state,
        positions: action.payload
      }
    }
  
    default: return state;
  }
}


export default scrollReducer;