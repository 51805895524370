import React from 'react'
import './Modal.scss'

class Modal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            scrollY: 0
        }

        this.handleClick = this.handleClick.bind(this)
        this.close = this.close.bind(this)
    }

    handleClick() {
        this.setState({
            isOpen: true,
            scrollY: window.scrollY
        })

        document.body.classList.add('overlay-open')
    }

    close() {
        this.setState({
            isOpen: false
        })
        document.body.classList.remove('overlay-open')
        window.scrollTo(0, this.state.scrollY);
    }

    render() {

        if (this.state.isOpen) {  
            document.getElementById('body').style.top = "-" + this.state.scrollY + "px";

            return (
            <div>
            <div className="overlay image" onClick={this.handleOverlayClick} >
                <div className="xycenter" onClick={(e) => { e.stopPropagation() }}>
                    <button className="close" onClick={this.close}>Close</button>
                    <img src={this.props.src}  alt="Big" />
                </div>
            </div>

            <button className="overlayThumbnail" onClick={this.handleClick}>
                <img src={this.props.src} alt="Thumbnail" />
            </button>
            </div>
            );
        }

        return (
            <button className="overlayThumbnail" onClick={this.handleClick}>
                <img src={this.props.src} alt="Thumbnail" />
            </button>
            );
      }
}

export default Modal;