import React from 'react'

class SharePinterest extends React.Component
{
render()
{
    return (

        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
      >
        <path
          fill="#4A4A4A"
          fillRule="evenodd"
          d="M9 0a9 9 0 00-3.612 17.243c-.025-.627-.004-1.383.156-2.067l1.158-4.903s-.287-.575-.287-1.425c0-1.333.774-2.33 1.736-2.33.818 0 1.214.616 1.214 1.352 0 .823-.525 2.053-.795 3.193-.226.956.478 1.734 1.42 1.734 1.705 0 2.854-2.19 2.854-4.785 0-1.972-1.329-3.448-3.745-3.448-2.73 0-4.43 2.035-4.43 4.308 0 .786.23 1.338.592 1.766.167.198.19.276.13.503-.043.164-.142.564-.184.721-.06.228-.244.31-.45.226-1.258-.513-1.843-1.89-1.843-3.439 0-2.556 2.156-5.623 6.432-5.623 3.436 0 5.698 2.488 5.698 5.156 0 3.531-1.962 6.169-4.857 6.169-.97 0-1.885-.525-2.198-1.121 0 0-.523 2.073-.633 2.473-.19.694-.564 1.388-.906 1.928A9 9 0 0018 9 9 9 0 009 0z"
          clipRule="evenodd"
        ></path>
      </svg>
    );

}
}

export default SharePinterest;