import React from 'react';
import './Algolia.scss'
import  AppLink  from 'Components/App/Link/Link'

import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';

const searchClient = algoliasearch('SJZNHEFVG6', '662e1b925e6ab2fa4bfdd0ddb317f769')

//import DelayLink from './DelayLink'

const Hit = ({ hit }) => <div className={"product"}>
  {hit &&
    <div>
      <AppLink className={' product-link'} to={"/product/" + hit.id}>

        <div className="img">
          {hit.default_image && <img alt="product" src={hit.default_image.src} width="100%" />}
        </div>

        <div className="prices">
          <span className="price"><span className="currency">$</span>{hit.default_variant && hit.default_variant.price.replace(".00", "")}</span>
          {hit.default_variant && hit.default_variant.compare_at_price && <span className="stroke">${Math.round(hit.default_variant.compare_at_price)}
          </span>}<br />
          {hit.order_count > 0
            && <span className="bought">{hit.order_count}&nbsp;bought&nbsp;this</span>}
        </div>
      </AppLink>



    </div>
  }
  <div className="clearfix"></div>
</div>;

class Algolia extends React.Component {


  render() {
    return <InstantSearch searchClient={searchClient} indexName="wow_integration">
     <div id="list">
      <SearchBox />
     
      <div className="row">
        <div className="products">
          <Hits hitComponent={Hit} />
        </div>
        </div>
      </div>

    </InstantSearch>
  }


}

export default Algolia;