import React from 'react'
import { store } from 'Redux/store'
import actions from 'Redux/actions';

import './Header.scss'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import Logo from '../../Svg/Logo'
import Search from './Search'
import Overlay from 'Components//Overlay'

import { withRouter } from 'react-router-dom'

import AppLink from 'Components/App/Link/Link'

class Header extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      showMenu: '',
    }

    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  componentWillMount() {

    this.unsubscribe = store.subscribe(() => {
      this.forceUpdate()
    });

  }

  componentDidUpdate() {

    const { settings } = store.getState()

    if (settings.notification && settings.notification.type)
      setTimeout(function () {
        store.dispatch(actions.removeNotification())
      }
        , 6000)
  }

  componentWillUnmount() {
    this.unsubscribe()
  }


  openMenu() {
    this.setState({ showMenu: 'show' })
  }

  closeMenu() {
    this.setState({ showMenu: '' })
  }

  toggleMenu() {
    this.state.showMenu
      ? this.closeMenu()
      : this.openMenu()
  }

  render() {
    const { auth, categories, settings } = store.getState();
    const notification = settings.notification
    return (
      <>
        {settings.overlay.content && 
        <Overlay type={settings.overlay.content} from={settings.overlay.from} />
        }
        <div className="navbar-container">

          <Navbar bg="bg-blue" expand="false" className="header">

            <AppLink className="navbar-brand" to="/">
              <Logo />
            </AppLink>


            <button aria-controls="basic-navbar-nav" type="button" aria-label="Toggle navigation" className={"navbar-toggler " + (this.state.showMenu ? 'collapsed' : '')} onClick={this.toggleMenu}><span className="navbar-toggler-icon"></span></button>


            <div><Search /></div>

            <div className={"navbar-collapse collapse" + this.state.showMenu} id="basic-navbar-nav">

              <Nav className="mr-auto">
                <div className="header-overlay" onClick={this.closeMenu}></div>
                <div className="nav-bg" onClick={this.closeMenu}></div>

                <NavDropdown title="Shop Categories" id="basic-nav-dropdown">

                  {categories.data && categories.data.length && categories.data.map((value, index) => {
                    return <AppLink onClick={this.closeMenu} className="dropdown-item" key={index} to={"/category/" + value.handle}> {value.title} </AppLink>
                  })}

                </NavDropdown>

                <AppLink onClick={this.closeMenu} menu="true" authonly="true" className="nav-link" to="/profile">Edit Profile</AppLink>
                {/*<Nav.Link href="#home">Manage Adresses</Nav.Link>
      <Nav.Link href="#home">Payment</Nav.Link>*/}
                <AppLink onClick={this.closeMenu} menu="true" authonly="true" className="nav-link" to="/orders">Order History</AppLink>

                <AppLink onClick={this.closeMenu} className="nav-link" to="/page/support">Support</AppLink>

                {auth.auth && <AppLink onClick={this.closeMenu} className="nav-link" to="/logout">Log out</AppLink>}

              </Nav>

            </div>
          </Navbar>

        </div>
        {notification && notification.type &&
          <div className={'notification ' + notification.type}>{notification.message}</div>}
      </>

    );
  }
}

export default withRouter(Header);