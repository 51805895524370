import React from 'react';
import axios from 'axios';
import config from 'config';
import {Redirect, withRouter} from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import 'react-intl-tel-input/dist/main.css';
import { Auth } from 'Services/Auth'
import { store } from 'Redux/store';
import actions from 'Redux/actions'
import  AppLink  from 'Components/App/Link/Link'
import './../../index.scss'

class PasswordUpdate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      responseReceived: false,
      current_password: '',
      new_password: '',
      confirm_password: '',
      errors: {},
    }

    this.update = this.update.bind(this);
    this.submit = this.submit.bind(this);
    this.updatePassword = this.updatePassword.bind(this);

    this.validator = new SimpleReactValidator();
  }


  update(e) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  }

  componentDidMount() {
    const { state } = this.props.location
    if (state)
      this.setState({ errors: { ...this.state.errors, password: state.error } })
  }

  updatePassword()
  {
    this.setState({ errors: { ...this.state.errors, new_password: "", confirm_password: "", current_password: "" } });

    const component = this
    axios.post(config.API_PASSWORD_UPDATE_URL, {
      current_password: this.state.current_password,
      new_password: this.state.new_password,
      confirm_password: this.state.confirm_password,  
    }, { headers: { 'X-Authorization': "bearer " + Auth.getToken() } })
      .then(function (response) {
     
        component.setState({ responseReceived: true });

        store.dispatch(actions.setNotification({ type: 'success', message: 'Password has been updated' }))

      })
      .catch(function (error) {
        component.setState({ errors: error.response.data.errors });
      });
  }

  submit(e) {
    e.preventDefault();

    if (this.validator.allValid()) {

      if (this.state.new_password !== this.state.confirm_password) {
        this.setState({ errors: { ...this.state.errors, new_password: "New password fields don't match", confirm_password: "New password fields don't match" } })
      }
      else {
        this.updatePassword()
      }

    } else {
      this.setState({ errors: this.validator.getErrorMessages() });
      this.forceUpdate();
    }
  }

  render() {
    const { errors } = this.state
    this.validator.message('current_password', this.state.current_password, 'required|min:6')
    this.validator.message('new_password', this.state.new_password, 'required|min:6')
    this.validator.message('confirm_password', this.state.confirm_password, 'required|min:6')

    if (!store.getState().auth.auth)
      return <Redirect to="/profile"/>

    return (
      <div>
       
        
          <form onSubmit={this.submit} className="profile-edit">
            <div className="h1-container">
            <h1 className="h1-header">
            <AppLink to="/profile" className="cancel">Cancel</AppLink>Password
            <button className="btn-primary btn">
                Save
            </button>
            </h1>
            </div>
            
            <div className="forgotten password-form">

              <div className="form-group signup-error">
              <input className={"form-control " + (errors.current_password && 'is-invalid')} id="current_password" type="password" name="current_password" placeholder="Current Password" onChange={this.update} />
                <div className={errors.current_password ? 'invalid-feedback visible' : 'invalid-feedback'}>{errors.current_password}
                </div>
              </div>

              <div className="form-group signup-error">
              <input className={"form-control " + (errors.new_password && 'is-invalid')} id="new" type="password" name="new_password" placeholder="New Password" onChange={this.update} />
                <div className={errors.new_password ? 'invalid-feedback visible' : 'invalid-feedback'}>{errors.new_password}
                </div>
              </div>

              <div className="form-group signup-error">
              <input className={"form-control " + (errors.confirm_password && 'is-invalid')} id="confirm_password" type="password" name="confirm_password" placeholder="Confirm Password" onChange={this.update} />
                <div className={errors.confirm_password ? 'invalid-feedback visible' : 'invalid-feedback'}>{errors.confirm_password}
                </div>
              </div>

            </div>
          </form>

          {/* responseReceived && <Redirect to={{
                    pathname: '/profile',
                    state: { message: "Password updated" }
                }}
              />*/}

      </div>
    );
  }
}

export default withRouter(PasswordUpdate);