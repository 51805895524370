import React from 'react'

class SearchOff extends React.Component
{
render()
{
    return (
    <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
    
    <g id="Icons-&amp;-Guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-180.000000, -180.000000)">
            <g id="icon/tab-bar/search-off" transform="translate(177.000000, 177.000000)">
                <rect id="bounding-shape" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="32" height="32"></rect>
                <path d="M13.9913345,24.132669 C8.39043011,24.132669 3.85,19.5922389 3.85,13.9913345 C3.85,8.39043011 8.39043011,3.85 13.9913345,3.85 C19.5922389,3.85 24.132669,8.39043011 24.132669,13.9913345 C24.132669,16.4920262 23.2238718,18.8514548 21.6070223,20.6883962 L28.106066,27.1874399 L28.106066,27.399572 L27.399572,28.106066 L27.1874399,28.106066 L20.6883962,21.6070222 C18.8514548,23.2238718 16.4920262,24.132669 13.9913345,24.132669 Z M22.8335355,13.9913345 C22.8335355,9.1079217 18.8747473,5.14913345 13.9913345,5.14913345 C9.1079217,5.14913345 5.14913345,9.1079217 5.14913345,13.9913345 C5.14913345,18.8747473 9.1079217,22.8335355 13.9913345,22.8335355 C16.1971976,22.8335355 18.2765454,22.0229344 19.8806126,20.5872986 L20.5864672,19.8801652 C22.0251344,18.270898 22.8335355,16.1942022 22.8335355,13.9913345 Z" fill="#0E0E0E"></path>
            </g>
        </g>
    </g>
</svg>

)
}
}

export default SearchOff;