import React from 'react'
import './Overlay.scss'
import Register from './Register/'
import Login from 'Components/Overlay/Login'
import Forgotten from 'Components/Overlay/Forgotten'
import Reset from 'Components/Overlay/Reset'
import { store } from 'Redux/store';
import actions from 'Redux/actions'

//import { store } from '../../Redux/store';
//import actions from 'Redux/actions'

class Overlay extends React.Component {

  constructor(props) {
    super(props)
    this.close = this.close.bind(this)
  }


  close() { console.log("overlay")
    console.log ("CLOSE: " + store.getState().settings.overlay.scroll)
    window.scrollTo(0, store.getState().settings.overlay.scroll);
    store.dispatch(actions.hideOverlay())
  }


  render() {

    document.getElementById('body').style.top = "-" + store.getState().settings.overlay.scroll + "px";

    return (
      <div className="overlay" onClick={this.handleOverlayClick}>
        <div className="xycenter" onClick={(e) => { e.stopPropagation() }}>
          <button className="close" onClick={this.close}>Close</button>
          {this.props.type === "register" && <Register />}
          {this.props.type === "login" && <Login />}
          {this.props.type === "forgotten" && <Forgotten />}
          {this.props.type === "reset" && <Reset />}
        </div>
      </div>
    );
  }
}

export default Overlay;