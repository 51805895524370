import React from 'react';
import axios from 'axios';
import config from 'config';
import {Redirect, withRouter} from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import {store} from 'Redux/store';
import {Auth} from 'Services/Auth'
import AppLink from 'Components/App/Link/Link'
import './../../index.scss'
import ButtonLoader from 'Components/Loader/ButtonLoader'
import isMobilePhone from "validator/es/lib/isMobilePhone";

//import actions from 'Redux/actions'

class PhoneUpdate extends React.Component {
    constructor(props) {
        super(props)

        const {auth} = store.getState()

        this.state = {
            email: auth.user.email,
            phone_number: auth.user.phone_number ? auth.user.phone_number : '',
            responseReceived: false,
            errors: {},
            exists: false,
            clicked: false
        }

        this.sendResetLink = this.sendResetLink.bind(this);
        this.update = this.update.bind(this);
        this.submit = this.submit.bind(this);

        this.validator = new SimpleReactValidator();
    }

    sendResetLink() {
        const component = this

        const {auth} = store.getState()
        const phone_number = auth.user.phone_number ? auth.user.phone_number.replace(" ", "") : ''
        const new_phone_number = this.state.phone_number.replace(" ", "")

        axios.post(config.API_PHONE_UPDATE_URL, {
            phone_number: phone_number,
            new_phone_number: new_phone_number,
        }, {headers: {'X-Authorization': "bearer " + Auth.getToken()}})
            .then(function (response) {

                component.setState({responseReceived: true, errors: {}})
            })
            .catch(function (error) {


                component.setState({responseReceived: true, errors: error.response.data.errors});
            });
    }


    update(phone_number, country) {
        if(!phone_number)
            return
        
        // Fix for IntlTelInput outputting number without prefix
        var prefix = '+' + country.dialCode;
        if(phone_number.indexOf(prefix) === -1) {
            phone_number = prefix + phone_number;
        }

        console.log(phone_number);

        this.setState({
            ...this.state,
            phone_number: phone_number,
            errors: {},
            responseReceived: false,
            clicked: false
        });
    }

    componentDidMount() {
        const {state} = this.props.location
        if (state)
            this.setState({errors: {...this.state.errors, phone_number: state.error}})
    }

    submit(e) {
        e.preventDefault();
        this.setState({clicked: true});
        //console.log(store.getState().auth.user.phone_number,this.state.phone_number);
        if (isMobilePhone(this.state.phone_number.replace(/[- ]/g,''))) {
            if (store.getState().auth.user.phone_number === this.state.phone_number.replace(/[- ]/g,'')) {
                this.setState({errors: {phone_number: 'Phone number provided is the same as currently used.'}});
                this.forceUpdate();
                setTimeout(function () {
                    this.setState({clicked: false});
                }.bind(this), 200)
            } else 
                this.sendResetLink();
        } else {
            this.setState({errors: {...this.state.errors, phone_number: "Phone number is invalid"}});
            this.forceUpdate();
            setTimeout(function () {
                this.setState({clicked: false});
            }.bind(this), 200)
        }
    }

    render() {
        const {responseReceived, errors} = this.state
        //    const { auth } = store.getState()


        if (!store.getState().auth.auth)
            return <Redirect to="/profile"/>

        return (
            <div>

                {(responseReceived === false || (responseReceived === true && errors.phone_number)) &&
                <form onSubmit={this.submit} className="profile-edit phone-edit" noValidate={true}>

                    <div className="h1-container">
                        <h1 className="h1-header">
                            <AppLink to="/profile" className="cancel">Cancel</AppLink>
                            Phone Number
                            {(this.state.clicked && !responseReceived) ?
                                <ButtonLoader/>
                                :
                                <button className="btn-primary btn">
                                    Next
                                </button>
                            }
                        </h1>
                    </div>

                    <div className="forgotten">


                        <div className="form-group phone-edit-group">

                            <IntlTelInput
                                containerClassName="intl-tel-input form-group signup"
                                onPhoneNumberChange={(status, value, countryData, number, id) => {
                                    this.update(number, countryData, value);
                                }}
                                onSelectFlag={(status, value, countryData, number, id) => {
                                    this.update(number, countryData, value);
                                }}
                                inputClassName={"form-control " + (errors.phone_number && 'is-invalid')}
                                fieldName="phone_number"
                                fieldId="phone"
                                formatOnInit="false"
                                defaultValue={this.state.phone_number}
                                format="true"
                                telInputProps={{pattern: '[0-9]*', inputMode: 'numeric'}}
                            />

                        </div>

                        <div className="form-group signup-error phone-error">
                            <div
                                className={errors.phone_number ? 'invalid-feedback visible' : 'invalid-feedback'}>{errors.phone_number}</div>
                        </div>

                    </div>
                </form>}
                {
                    (!errors.phone_number && responseReceived) &&

                    <div className="settings-container">
                        <div className="row settings-header">
                            <div className="col-3"></div>
                            <div className="col-6"><h1>Confirmation</h1></div>
                            <div className="col-3"><AppLink to="/profile" className="continue">Continue</AppLink></div>
                        </div>

                        <div className="product-container text">We sent a SMS
                            to <span>{this.state.phone_number}</span> with a confirmation link. Please click on it to
                            confirm the new phone number.
                        </div>
                    </div>

                }

            </div>
        );
    }
}

export default withRouter(PhoneUpdate);