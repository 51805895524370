import React from 'react';
import axios from 'axios';
import config from 'config';
import { withRouter } from 'react-router-dom'
import AppLink from 'Components/App/Link/Link'
import SimpleReactValidator from 'simple-react-validator'
import 'react-intl-tel-input/dist/main.css';
//import { store } from '../../../Redux/store';
//import actions from 'Redux/actions'
import ButtonLoader from 'Components/Loader/ButtonLoader'


class Forgotten extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      responseReceived: false,
      errors: {},
      exists: false,
      loading: false
    }

    this.sendResetLink = this.sendResetLink.bind(this);
    this.update = this.update.bind(this);
    this.submit = this.submit.bind(this);
    this.onFocus = this.onFocus.bind(this);

    this.validator = new SimpleReactValidator();
  }

  onFocus(e) {
    this.setState({ errors: { ...this.state.errors, [e.target.name]: '' } });
  }

  sendResetLink() {
    const component = this

    axios.post(config.API_FORGOTTEN_URL, {
      email: this.state.email
    })
      .then(function (response) {
        component.setState({ responseReceived: true, loading: false })
      })
      .catch(function (error) {
        component.setState({ errors: error.response.data.errors, loading: false })

      });
  }

  update(e) {
    this.setState({
      ...this.state,
      email: e.target.value,
    });
  }

  componentDidMount() {
    const { state } = this.props.location
    if (state)
      this.setState({ errors: { ...this.state.errors, email: state.error } })
  }

  submit(e) {
    e.preventDefault();
    this.setState({ loading: true });
    this.validator.message('email', this.state.email, 'required|email')

    if (this.validator.allValid()) {
      this.sendResetLink();
    } else {
      this.setState({ errors: this.validator.getErrorMessages(), loading: false });
      this.forceUpdate();
    }
  }

  render() {
    const { responseReceived, errors } = this.state


    return (
      <div className="forgottenPassword">
        {responseReceived === false &&
          <form onSubmit={this.submit}>
            <p className="prompt">Enter email you used to sign up and we’ll send you a link to reset your password.</p>

            <div className="forgotten">
              <div className="form-group email forgotEmail">
                <input className={"form-control " + (errors.email && 'is-invalid')} id="email" name="email" type="email" onFocus={this.onFocus} placeholder="Email" onChange={this.update} />
                <div className="invalid-feedback">{errors.email}</div>
              </div>


              <button className={this.state.loading ? "loading btn-primary btn" : "btn-primary btn"}>
                {this.state.loading ? <ButtonLoader /> : 'Continue'}
              </button>
              <p className="center cancelLink">
                <AppLink to="/login" overlay="login" className="forgot">Cancel</AppLink>
              </p>
            </div>
          </form>}
        {
          responseReceived && <span className="prompt center">Ok, we sent email to {this.state.email} with link to reset your password.</span>
        }
      </div>
    );
  }
}

export default withRouter(Forgotten);