import React from 'react'

class UserOn extends React.Component {
    render() {
        return (
            <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Icons-&amp;-Guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icons" transform="translate(-564.000000, -180.000000)">
                        <g id="icon/tab-bar/user-on" transform="translate(561.000000, 177.000000)">
                            <rect id="bounding-shape" fillOpacity="0" fill="#FFFFFF" x="0" y="0" width="32" height="32"></rect>
                            <path d="M16,16.15 C12.6034488,16.15 9.85,13.3965512 9.85,10 C9.85,6.60344879 12.6034488,3.85 16,3.85 C19.3965512,3.85 22.15,6.60344879 22.15,10 C22.15,13.3965512 19.3965512,16.15 16,16.15 Z M28.15,28 L28,28.15 L4,28.15 L3.85,28 L3.85,25 C3.85,21.6034488 6.60344879,18.85 10,18.85 L22,18.85 C25.3965512,18.85 28.15,21.6034488 28.15,25 L28.15,28 Z" id="Combined-Shape" fill="#0E0E0E"></path>
                        </g>
                    </g>
                </g>
            </svg>

        )
    }
}

export default UserOn;